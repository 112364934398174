@import '../variables.scss';
@import '../mixins.scss';

// custom segment
.custom-segment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 0.031rem solid #d6d6d6 !important;

  .segment-heading {
    display: flex;
    align-self: center;
    @include Body-Default-Semibold();
  }

  .projected-activity-modal-toggle {
    color: $gradientRight !important;
  }

  .projected-activity-modal-heading {
    @include Body-Small($gradientRight);
    margin-bottom: auto !important;
    margin-top: auto !important;
  }

  .segment-heading-custom {
    display: flex;
    align-self: center;
    margin: 0.313rem 0 0 0;
    @include Body-Default;
  }

  .segment-icon {
    margin-right: 0.75rem !important;
  }

  .segment-icon-cursor {
    margin-right: 0.75rem !important;
    border: none;
    cursor: pointer !important;
  }

  .notification-link {
    margin-left: 11.25rem !important;
    @include Body-Small($Primary);
  }
}

.activity-participant-sub {
  @include Body-Small($Text-Secondary);
  margin-top: -0.625rem !important;
}

.header-title-flex {
  display: flex !important;
  flex-direction: column !important;
  margin-left: -0.438rem;
}

.custom-segment-without-border-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .segment-heading {
    display: flex;
    align-self: center;
    @include Body-Small($info);
    margin: 0.313rem 0;
  }

  .segment-heading-custom {
    display: flex;
    align-self: center;
    @include Body-Small($Text-Secondary);
    margin: 0.313rem 0 0 0;
  }

  .segment-icon {
    margin-right: 0.75rem !important;
  }

  .segment-icon-cursor {
    margin-right: 0.75rem !important;
    cursor: pointer !important;
  }

  .notification-link {
    margin-left: 11.25rem !important;
    @include Body-Small($Primary);
  }
}

@media only screen and (max-width: 101.5rem) {
  .info-pulse {
    margin-left: 0.625rem !important;
    display: block;
    cursor: pointer;
    animation: pulse 2s infinite;
    background-color: $gradientRight;
    color: white;
  }

  .info-pulse-close {
    margin-left: 0.625rem !important;
    display: block;
    cursor: pointer;
    animation: pulse 2s infinite;
    background-color: $gradientRight;
    color: white !important;
  }
}

.info-pulse {
  display: block;
  cursor: pointer;
  animation: pulse 2s infinite;
  background-color: $gradientRight;
  color: white;
}

.info-pulse:hover {
  animation: none;
}

.browse-file-width-button {
  width: 100% !important;
}

.info-pulse-close {
  display: block;
  cursor: pointer;
  animation: pulse 2s infinite;
  background-color: $gradientRight;
  color: white !important;
}

.info-pulse-close:hover {
  animation: none;
  color: white !important;
}

.info-window {
  background-color: white;
  height: auto;
  width: auto;
  max-width: 500px;
  min-height: 6.25rem;
  border-radius: 0.313rem;
  padding: 0.938rem;

  .info-description {
    flex: 1;
    @include Body-Small($info);
    text-align: center;
  }

  .info-button {
    flex: 1;
    @include Body-Default-Semibold($gradientRight);
    padding: 0.938rem 0;
    text-align: center;
    width: 100%;
  }
}

.ui.negative.message {
  background-color: $Error-light;
  .notification-title {
    @include Body-Default-Semibold($Error);
    padding-bottom: 1rem !important;
  }

  .notification-intro {
    @include Body-Default($Error);
  }
}

.user-pro-text {
  text-align: left;
  @include Body-Default-Semibold($info);
  cursor: pointer;
}

.accordion-ev {
  border: 0.063rem solid #ef8123 !important;
  border-radius: 0.25rem !important;
  border-top: 0.063rem solid #ef8123 !important;
}

.accordion-ev:hover {
  background: rgba(239, 129, 35, 0.1) !important;
}

.show-question-ac {
  background: #fafafa !important;

  box-sizing: border-box !important;
  border-radius: 0.25rem !important;
  border-bottom: none !important;
}

.user-profile-dropdown {
  .ui.dropdown .menu > .item {
    border-top: 0.063rem solid #e0e0e0;
  }
}

.user-profile-drop {
  .img-wid {
    object-fit: cover;
  }

  .ui.mini.image,
  .ui.mini.images .image,
  .ui.mini.images img,
  .ui.mini.images svg {
    width: 2.188rem !important;
    height: 2.188rem !important;

    border: 0.031rem solid #e0e0e0;
    border-radius: 0.25rem;
    padding: 0.25rem;
  }
}

.drop-in-userName {
  .img-wid {
    object-fit: cover;
  }

  .ui.mini.image,
  .ui.mini.images .image,
  .ui.mini.images img,
  .ui.mini.images svg {
    width: 46.67px !important;
    height: 46.67px !important;
  }
}

@media only screen and (min-width: 101.5rem) {
  .user-profile-drop {
    .img-wid {
      margin-bottom: 0.5rem;
    }
  }
}

.dropdown-adjust {
  display: flex;
  flex-direction: row;

  .drop-in-userName {
    width: auto;
    cursor: default;
    padding: 0rem 0.313rem 0rem 0rem;
    display: flex;
    align-items: center;
    flex-direction: row;

    .img-wid {
      object-fit: cover;
    }

    .user-pro-text {
      overflow: hidden;
      white-space: nowrap;
      -o-text-overflow: ellipsis;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      font-family: $fontFamily;
      margin-left: 0.625rem;
      font-style: normal;
      font-weight: 600;
      font-size: $Body-Default;
      line-height: 1.5rem;
    }
  }

  .user-profile-drop {
    display: flex;
    flex-direction: row;
    margin-top: 0.938rem;
    margin-right: $spacing-xl;
    justify-content: center;
    align-items: center;

    .name-wid {
      margin: auto;
      margin-left: 0.938rem;
    }

    .img-wid {
      object-fit: cover;
    }

    .name {
      @include Body-Default-Semibold;
    }

    .icon-re {
      margin: auto;
      margin-left: 0.5rem;
    }
  }

  .ui.dropdown .menu > .item {
    .text-color {
      @include Body-Default;
    }
  }

  .ui.dropdown .menu > .item:hover {
    background-color: rgba(239, 129, 35, 0.1) !important;

    .text-color {
      color: $gradientRight;
    }
  }
}

.user-info {
  i.large.icon {
    font-size: 1.2em;
    padding-bottom: 22px !important;
  }

  display: flex;
  flex-direction: column;

  .user-pro-text {
    text-align: left;
    @include Body-Default-Semibold;
    cursor: pointer;
    margin: 0rem;
  }

  .user-sub-text {
    @include Body-Small($Gray-70);
  }
}

.av-upload {
  .upload-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .row {
      margin: 0rem !important;
    }
  }

  .upload-area {
    display: flex;
    flex: 1;
    flex-direction: column;

    input[type='file'] {
      display: none;
    }

    .custom-file-upload {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 2.857rem;
      width: 8.75rem;
      border: 0.063rem solid $gradientRight;
      border-radius: 0.25rem;
      background-color: $white;
      cursor: pointer;
      @include Body-Small($gradientRight);
      margin-top: 0.938rem !important;
    }

    .custom-file-upload:hover {
      background-color: $gradientRight;
      color: $white !important;
    }

    .info-sec {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
    }
  }

  .profile-picture {
    border-radius: 0.25rem;
    width: 9.375rem !important;
    height: 9.375rem !important;
  }

  .profile-pic-btn {
  }

  .upload-image-area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 2;

    .area {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.25rem;
      overflow: hidden;
    }

    img {
      max-height: 90% !important;
      max-width: initial;
    }
  }

  @media only screen and (max-width: 767px) {
    .upload-container {
      flex-direction: column;
      align-items: flex-start !important;
    }

    .upload-image-area {
      margin-top: 0.625rem;
    }
  }
}

.browse-area {
  display: flex;
  flex-direction: column;

  .browse-container {
    display: flex;
    flex-direction: row;
  }

  .clear-browse {
    display: flex;
    align-items: center;
    color: $gradientRight;
    font-family: $fontFamily;
    font-size: $Body-Small;
    font-weight: 600;
    line-height: 1.063rem;
    text-align: center;
    padding-left: 0.625rem;
    padding-top: 0.313rem;
    cursor: pointer;
  }

  .clear-browse-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gradientRight;
    font-family: $fontFamily;
    font-size: $Body-Small;
    font-weight: 600;
    line-height: 1.063rem;
    text-align: center;
    padding-left: 0.625rem;
    padding-top: 0.313rem;
    cursor: pointer;
    margin-top: 0.625rem;
  }
}

.ui.basic.modal > .image-view {
  background-color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ui.basic.modal > .image-view-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-inputs {
  font-size: $Body-Small !important;
}

@media only screen and (max-width: 767px) {
  .ui.grid > .profile-row {
    margin-left: 32px !important;
    margin-right: 41px !important;
  }
}

@media only screen and (max-width: 767px) {
  .ui.grid > .profile-row {
    margin-left: $spacing-xl !important;
    margin-right: 2.563rem !important;
  }
}

@media print {
  .no-print {
    display: none !important;
  }

  .main-content {
    margin-left: 0 !important;
  }
}

.issues-box {
  padding: 1rem $spacing-xl 1rem $spacing-xl;
  background-color: white;

  .padding-issue {
    background: #ffebee;
    border: 0.031rem solid #d50000;
    border-radius: 0.25rem;
    padding-top: 1rem;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
  }

  ul {
    font-size: $Body-Small;
    color: #d50000;
  }
}

.issues-box-info {
  padding: 1rem $spacing-xl 1rem $spacing-xl;
  background-color: white;

  .padding-issue {
    background: #dff0ff;
    color: $Info;
    border-radius: 0.25rem;
    border: 0.031rem solid $Info;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
  }

  ul {
    font-size: $Body-Default !important;
    color: $blue;
  }
}

.info-box {
  padding: 1.25rem;
  padding-left: 1.875rem;
  background-color: #dff0ff;
  color: $complete;

  p {
    font-size: $Body-Small;
    font-weight: bold;
  }
}

.survey-gap {
  margin-bottom: 1.25rem;
  font-weight: normal;
}

.survey-message {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.custom-gen-questions {
  margin-top: 0.313rem !important;

  .form-group {
    margin-bottom: 0 !important;
    font-weight: normal;
  }
}

.custom-gen-questions-textarea-style {
  min-height: 152px !important;
  border-radius: 0.5rem !important;
  padding-bottom: 5.625rem !important;
}

@media only screen and (min-width: 992px) {
  .custom-gen-questions-textarea-style {
    min-width: 817px !important;
  }
}

@media only screen and (min-width: 1130px) {
  .custom-gen-questions-textarea-style {
    min-width: 940px !important;
  }
}

.bold-text {
  font-weight: bold;
}

.back-button-segment {
  color: black;
  margin-right: 0.313rem;
}

.slider-margin {
  margin: 3.125rem 0rem 3.125rem 0rem;
}

.slider-info-text {
  display: flex;
  justify-content: space-between;
  width: 100% !important;

  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
  /* identical to box height, or 200% */
  text-align: center !important;
  color: #000000 !important;
}

.logo-container {
  display: flex;
  justify-content: center;

  .ui.small.image,
  .ui.small.images .image,
  .ui.small.images img,
  .ui.small.images svg {
    width: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .logo-container {
    display: flex;
    justify-content: center;

    .ui.small.image,
    .ui.small.images .image,
    .ui.small.images img,
    .ui.small.images svg {
      width: auto !important;
      height: 53.4.688rem !important;
    }
  }
}

.notify-message {
  margin-top: 0.625rem;
  text-align: left;

  .collapse {
    &:not(.show) {
      display: inline !important;
    }
  }
}

.notification-btn {
  display: flex;
  padding-top: 0.625rem;
  justify-content: flex-start !important;

  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  text-decoration-line: underline;
  color: #d50000;

  &:hover {
    cursor: pointer;
  }
}

.hidden-postcode .invalid-feedback {
  margin-top: -0.625rem;
}

.number-participants-div {
  display: flex;
  flex-direction: column;

  .number-participants-input {
    height: 2.857rem;
    min-width: 370px;
    width: 370px;
    margin-bottom: 0.938rem;
    border-radius: 0.5rem !important;

    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: $Body-Small;
    line-height: 1.25rem;
    /* identical to box height, or 143% */

    color: #000000;
  }

  .number-participants-disabled {
    input {
      background: #eaecef;
    }
  }
}

.disabled-eval-plan {
  opacity: 0.5;
}

.disabled-eval-edit-plan {
  opacity: 0.5;
  cursor: not-allowed;
}

.invalid-city {
  border-color: red !important;
}

.invalid-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media only screen and (max-width: 767px) {
  .nfheader {
    font-size: 8.125rem !important;
    line-height: 160px !important;
  }
}

.header-fixed {
  position: sticky;
  top: 0;
}

.rating-css {
  width: 60%;
}

.rating-css span {
  width: 10%;
}

.full-rating {
  background: #ef8123;
  border: 0.063rem solid #ef8123;
  box-sizing: border-box;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  max-height: 3rem;
  max-width: 3rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  width: 4vw;
  height: 4vw;
}

.empty-rating {
  background: #ffffff;
  border: 0.063rem solid #ea8c00;
  box-sizing: border-box;
  min-height: 1.5rem;
  min-width: 1.5rem;
  max-height: 3rem;
  max-width: 3rem;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  color: #ea8c00;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 480px) {
  .rating-css {
    width: 100%;
  }

  .slider-info-text {
    width: 100%;

    font-size: $Body-Small;
  }

  .slider-margin {
    margin: 0.625rem 0rem;
  }

  .eight {
    width: 100%;
  }
}

.not-found {
  display: flex;
  height: 100vh;
  padding: 0vh 40vh;
  justify-content: center;
  align-items: center;
  background-color: white;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .nfheader {
      color: $info;
      font-family: $fontFamily;
      font-size: 12.5rem;
      font-weight: 600;
      line-height: 250px;
      text-align: center;
    }

    .nfdetails {
      color: $info;
      font-family: $fontFamily;
      font-size: $h6;
      line-height: 2.125rem;
      text-align: center;
    }

    .ui.button.nfbutton {
      margin-top: 1.875rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 3.125rem;
      width: 277px;
      background-color: $gradientRight;
      color: white;
      border-radius: 0.25rem;
      box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
      font-family: $fontFamily;
      font-size: $Body-Default;
      font-weight: bold;
      line-height: 1.375rem;
      text-align: center;
    }

    .ui.button.nfbutton:hover,
    .ui.buttons.nfbutton .button:hover {
      background-color: $amber;
      color: $white;
    }
  }
}

.permission-denied {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80vh;

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .status {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: 11.25rem;
      letter-spacing: 15.98px;
      color: #3b3b3b;
    }

    .header {
      margin-top: 5rem;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: bold;
      font-size: $h4;
      color: #3b3b3b;
    }

    .note {
      text-align: center;
      margin-top: 1.25rem;
      margin-left: 12.5rem;
      margin-right: 12.5rem;
      font-family: $fontFamily;
      font-style: normal;
      font-weight: normal;
      font-size: $Body-Large;
      color: #3b3b3b;
    }
  }
}

tbody {
  tr {
    td {
      border-bottom: 0.063rem solid $background;
      font-size: $Body-Small;
    }

    .more-height {
      height: 9.375rem !important;
    }

    .no-data {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 6.25rem;

      p {
        font-size: $Body-Small;
      }
    }

    .no-data:hover {
      background-color: none !important;
    }
  }
}

.ui.nav-image.image,
.ui.nav-image.images .image,
.ui.nav-image.images img,
.ui.nav-image.images svg {
  width: 1.5rem;
  height: auto;
}

.ui.nav-image-resize.image {
  width: 1.75rem;
  height: auto;
}

.mob-toggle-container {
  display: flex !important;
  align-items: center;
}

.no-padding {
  padding: 0 !important;
}

.disabled-item {
  padding: 0.688rem 1rem;
  pointer-events: none;
}

.public-error-icon {
  margin-bottom: 26px;
}

.locked-activity {
  padding: 1.25rem 0rem;
}

.rating-css {
  width: 60%;
}

.rating-css span {
  width: 10%;
}

.full-rating {
  background: #ef8123;
  border: 0.063rem solid #ef8123;
  box-sizing: border-box;

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  max-height: 3rem;
  max-width: 3rem;
  min-height: 1.5rem;
  min-width: 1.5rem;
  width: 4vw;
  height: 4vw;
}

.empty-rating {
  background: #ffffff;
  border: 0.063rem solid #ea8c00;
  box-sizing: border-box;
  min-height: 1.5rem;
  min-width: 1.5rem;
  max-height: 3rem;
  max-width: 3rem;
  width: 4vw;
  height: 4vw;
  border-radius: 50%;
  color: #ea8c00;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 480px) {
  .rating-css {
    width: 100%;
  }

  .slider-info-text {
    width: 100%;

    font-size: $Body-Small;
  }

  .slider-margin {
    margin: 0.625rem 0rem;
  }
}

.see-more-see-less-toggle {
  color: $gradientRight !important;
  font-weight: 400 !important;
  cursor: pointer;
  width: fit-content;
}

.disbled-comp {
  opacity: 0.9 !important;
  color: rgba(40, 40, 40, 0.9) !important;
  cursor: not-allowed !important;
}

.disabled-padding {
  .ui.segment {
    padding: 0rem !important;
    margin-left: 200px;
  }
}

.segment-change {
  margin-top: -8px;
  margin-bottom: -10px;
}

.segment-change1 {
  margin-top: -10px;
  margin-bottom: -10px;
}

.sub-child-segment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

// react-bootstrap-daterangepicker styles
.date-range-container {
  // daterangepicker container area with label
  display: flex;
  flex-direction: column;
}

.date-range-chart {
  // react-bootstrap-daterangepicker custom component
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  height: 40px !important;
  border: 0.063rem solid rgba(34, 36, 38, 0.15);
  border-radius: 0.5rem !important;

  .date-range-chart-date {
    font-size: $Body-Small !important;
    padding: 0.375rem 0.75rem;
  }

  // remove bootstrap default styles (border/outline styles)
  input {
    border: 0;
    background-color: transparent !important;
    box-shadow: #66afe9;
    width: 92% !important;
  }

  input:focus {
    border: 0;
    border-color: transparent;
    outline: 0 none;
  }

  .calendar {
    margin-bottom: 0.29em;
  }
}

.msg-action,
.msg-action:hover {
  color: #ef8123;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: $Body-Small;
  line-height: 1.25rem;
  text-align: right;
  cursor: pointer;
}

.project-partner-minified-txt {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Default;
  line-height: 20px;
  color: #ef8123;
  cursor: pointer;
}

.formik-custom-multiple-field {
  margin-top: 20px;
  margin-bottom: 1rem;
  width: 100%;
}

.formik-custom-field {
  margin-top: 20px;
  margin-bottom: 1rem;
  width: 100%;

  label,
  .label {
    @include Body-Small($Text-Secondary);
    display: inline-block;
  }

  .required-stars:after {
    content: ' *';
    color: red;
  }

  .custom-radio {
    label {
      display: flex !important;

      input {
        margin-right: 5px !important;
      }
    }
  }

  .ui.input {
    width: 100% !important;
  }

  .ui.selection.dropdown {
    width: 100% !important;
  }

  .ui.scrolling.dropdown .menu {
    max-height: 13rem !important;
  }

  .invalid-feedback {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }

  .optionGroupText {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  .info-text {
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 150% !important;
    color: #8c8c8c !important;
  }
}

.password-fail {
  font-style: normal;
  font-weight: 400;
  font-family: $fontFamily;
  font-size: 12px;
  line-height: 18px;
  color: #757575;
}

.password-passed {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #05944f;
}

.slider-info-muted {
  display: flex;
  justify-content: space-between;
  width: 100% !important;

  font-family: Inter !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: $Body-Small !important;
  line-height: 1.25rem !important;
  /* identical to box height, or 200% */
  text-align: center !important;
  color: $subLabel !important;
}

//ManagementSummary
.management-summary {
  .header-container {
    background: $background !important;
    border-bottom: 0.5px solid #e0e0e0;
    width: -webkit-fill-available;

    .row.header-margin {
      margin: 1.71429rem 2.28571rem !important;
    }

    .flex-start {
      @include display-flex(center, flex-start);
      flex-direction: column;
    }

    .title {
      @include Body-Default($Gray-70);
      margin-bottom: 0.571428571rem;
    }
    .content {
      @include Body-Default();
      margin-bottom: 0.571428571rem;
    }
  }

  .sub-header-container {
    .row.subheader-margin {
      margin: 2.28571rem 2.28571rem 1.71429rem 2.28571rem !important;
      display: flex;
      align-items: flex-end;
    }

    .flex-end {
      display: flex;
      justify-content: flex-end;
    }

    .content {
      @include Body-Small();
      display: flex;
      align-items: center;
      .count {
        @include Body-Default();
        margin-left: 2.28571rem;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .activity-overview-alignment {
      display: unset !important;
    }
  }
  .activity-overview-alignment {
    display: flex;
  }
  .activity-name {
    margin-right: $spacing-md;
  }
}

.table-with-inside-header {
  tr {
    border-bottom: 1px solid #e0e0e0;
  }
  .table-heading td {
    padding-top: 0.571428571rem !important;
    padding-bottom: 0.571428571rem !important;
    background: $Gray-10 !important;
    @include Body-Default-Semibold($Gray-70);
  }
  .table-column-header th {
    padding-top: 0.571428571rem !important;
    padding-bottom: 0.571428571rem !important;
  }

  .subtitle {
    @include Body-Default($Gray-70);
  }
}
.sub-data-table {
  background: #fafafa;
}
.sub-first-column {
  margin-left: 40px;
}
.partner-total-column {
  color: black;
}

.arrow-first-column {
  display: flex;
  flex-direction: row;
}
.multi-save-button {
  display: flex;
  padding: 14px 13px;
  cursor: pointer;
  border: 1px solid #e0e0e0;
  margin-top: -28px;
  flex-direction: row-reverse;
  color: $gradientRight;
  background: $white;
}

.formik-custom-multiple-field {
  .ui.pointing.upward.dropdown .menu,
  .ui.top.pointing.upward.dropdown .menu {
    top: auto !important;
    bottom: 100% !important;
    /* margin: 0 0 0.78571429rem; */
    border-radius: 0.28571429rem;
  }

  .ui.selection.active.dropdown .menu {
    border-color: $white !important;
    box-shadow: none !important;
  }

  .text-empty-info {
    font-family: 'Inter', sans-serif;
    font-size: $Body-Small !important;
    font-weight: 400 !important;
    line-height: 1.142857143rem !important;
    color: $Error !important;
  }
}

// .sort-order-style {
//   margin-left: 0px;
//   width: 300px; // Adjust this value to your needs
//   white-space: nowrap; // Prevents wrapping to the next line
// }
@media (max-width: 600px) {
  /* Adjust this value to the point where the layout breaks */
  .sort-order-style {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0px;
    width: 300px; // Adjust this value to your needs
    white-space: nowrap; // Prevents wrapping to the next line
  }
}
.not-verified-email {
  color: #8c8c8c !important;
  font-size: 12px;
  font-weight: 400;
}
.formik-permission-table-custom-border {
  border: 1px solid $Gray-40;
  border-radius: 0.571rem;
  @include set-margin(0.5rem, 0, 2.051rem, 0);
  width: 100%;
}

.formik-permission-table-custom {
  border: none !important;
  border-radius: 0.571rem !important;
  thead {
    tr {
      th {
        background-color: #ffffff !important;
        border: none !important;
        border-radius: 0.5rem !important;
        padding-top: 1rem !important;
      }
      th:first-child {
        padding-left: 1rem !important;
      }
    }
  }

  tbody {
    tr {
      padding: 0rem !important;

      td {
        padding: 1rem 0rem 0rem 1rem !important;
        border: 0rem !important;
      }

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.formik-permission-activity-section-cell {
  margin-left: 0.938rem !important;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Small;
  line-height: 1.25rem;
  /* identical to box height, or 143% */
  color: #000000;
}

.no-feature-warning-box {
  background-color: white;
  margin: 1rem 0;
  .padding-issue {
    background: $Info-light;
    border: 0.031rem solid $Info;
    border-radius: 0.25rem;
    padding: 1rem;
    line-height: 1.4285em;
    color: rgba(0, 0, 0, 0.87);
  }

  ul {
    font-size: $Body-Small;
    color: #d50000;
  }
}

.deactivation-modal-content .no-feature-warning-box {
  margin-top: 0 !important;
}
