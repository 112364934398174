@import '../../variables.scss';
@import '../../mixins.scss';

// activity
.activity-cont {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-left: 2rem;
}

.dashboard-act-container {
  width: 100%;

  .dashboard-act-heading {
    margin-top: 1.286rem;
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Default !important;
    font-weight: 600;
    line-height: 1.375rem;
    margin-left: $spacing-xl;
    margin-bottom: $spacing-sm;
  }

  .dashboard-act-info {
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Small;
    font-weight: 300;
    line-height: 1.188rem;
    margin-left: 1.875rem;
    opacity: 0.75;
    margin-bottom: $spacing-xl !important;
    margin-top: 1.286rem !important;
  }

  .activity-count {
    color: $info;
    font-family: $fontFamily;
    font-size: $h3;
    font-weight: bold;
    line-height: 55px;
    margin-left: $spacing-xl;
    margin-right: 1rem;
  }

  .activity-count-owned {
    color: $info;
    font-family: $fontFamily;
    margin-right: 1rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: $Body-Large;
    line-height: 1.75rem;
    letter-spacing: -0.017px;
  }

  .activity-count-invited {
    margin-right: 1rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: $Body-Large;
    line-height: 1.75rem;
    letter-spacing: -0.017px;
  }

  .activity-title {
    @include Body-Large($Text-Secondary);
  }
}

.dashboard-act-container-invited {
  width: 100%;

  .dashboard-act-heading {
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Default !important;
    font-weight: 600;
    line-height: 1.375rem;
    margin-left: $spacing-xl;
    margin-bottom: $spacing-sm;
    margin-top: 0.75rem;
  }

  .dashboard-act-info {
    @include Body-Small($Text-Secondary);
    margin-left: $spacing-xl;
    margin-bottom: 1.286rem !important;
    margin-top: 0.375rem !important;
  }

  .activity-count {
    @include h6();
    margin-left: $spacing-xl;
    margin-right: 1rem;
  }

  .activity-count-owned {
    margin-right: 1rem;
    @include h6();
  }

  .activity-count-invited {
    margin-right: 1rem;
    @include h6();
  }

  .activity-title {
    @include Body-Large($Text-Secondary);
  }
}

// recent activity
.recent-activity-segments {
  border: none !important;
  box-shadow: none !important;
  height: 14.3rem !important;
  overflow: auto !important;

  .notify-info-custom {
    display: flex;
    align-items: center;
    border-bottom: 0.063rem solid #eeeeee !important;
    padding: 1.286em 1em !important;
    min-height: 76px;
    justify-content: space-between;
  }

  .notify-info-custom:hover {
    cursor: pointer !important;
    background-color: #f5f5f5;
  }

  .ui-segment-custom {
    border-bottom: 0.063rem solid #eeeeee !important;
    background: rgba(245, 245, 245, 1) !important;
  }

  .ui.segment:last-child {
    border-bottom: none !important;
  }
}

.recent-activity-name {
  color: $info;
  font-family: $fontFamily;
  font-size: $Body-Default;
  line-height: 1.375rem;
  font-weight: bold;
}

.recent-activity-action {
  color: $info;
  font-family: $fontFamily;
  font-size: $Body-Default;
  line-height: 1.375rem;
}

.recent-activity-event {
  color: $info;
  font-family: $fontFamily;
  font-size: $Body-Default;
  line-height: 1.375rem;
  font-style: italic;
}

.recent-activity-date {
  @include Body-Small($Text-Secondary);
  margin-bottom: 0rem !important;
}

.critical-info {
  margin-left: 0.625rem !important;
  border-radius: 0.25rem !important;
  background: #ffebee;
  @include Body-Small($Error);
  text-align: center !important;
  padding: 0.4em 0.833em;
  margin-top: -0.1rem;
}

@mixin horizontal-label {
  margin: 0 0.5em 0 0;
  padding: 0.4em 0.833em;
  min-width: 3em;
  text-align: center;
}

.ui.orange.label {
  @include horizontal-label;
  border-radius: 0.25rem !important;
  @include Body-Small($Warm);
  text-align: center !important;
  margin-top: -0.063rem;
  background-color: $Warm-light !important;
  margin-left: 0.5rem;
}

.ui.red.label {
  @include horizontal-label;
  border-radius: 0.25rem !important;
  @include Body-Small($Error);
  text-align: center !important;
  margin-top: -0.063rem;
  background-color: $Error-light !important;
}

.ui.black.label {
  @include horizontal-label;
  border-radius: 0.25rem !important;
  @include Body-Small($Gray-70);
  text-align: center !important;
  margin-top: -0.063rem;
  background-color: $Gray-20 !important;
}

.ui.yellow.label {
  @include horizontal-label;
  border-radius: 0.25rem !important;
  @include Body-Small($Warm);
  text-align: center !important;
  margin-top: -0.063rem;
  background-color: $Warm-light !important;
}

.info-button {
  cursor: pointer;
}

.amber-color {
  color: $amber;
}

.green-color {
  color: $green;
}

.blue-color {
  color: $blue;
}

.notification-view {
  color: $gradientRight;
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-weight: 600;
  line-height: 1.375rem;
  cursor: pointer;

  label {
    cursor: pointer !important;
  }
}

.notify-info-main {
  display: flex;
  flex-direction: row;
}

.notify-info-main-icon {
  padding-top: 0rem !important;
}

.notify-dashboard-main-icon {
  margin-left: $spacing-sm !important;
}

.dashboard-notification {
  .i.large.icon,
  i.large.icons {
    @include Body-Small($Error);
  }
}

.notify-info-main-icon-wrap {
  width: 1.25rem !important;
}

.notify-info {
  opacity: 0.8;
  margin: 0rem 0rem;
  @include Body-Default();
}

.notify-new-label {
  color: #dc3545;
  background: #e8cfcf;
  border-radius: 0.313rem;
  padding: 0rem 0.375rem;
}

.dashboard-pagination {
  border-bottom: none !important;

  .item {
    background-color: #f3f3f3 !important;
    color: #000000 !important;
    border-radius: 3.125rem !important;
    border: 0 !important;
    padding: 0.625rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 1rem !important;

    &.active {
      border: 0 !important;

      &:hover {
        background-color: #ef8123 !important;
        color: #fff !important;
      }

      background-color: #ef8123 !important;
      color: #fff !important;
    }

    &:hover {
      background-color: #f3f3f3 !important;
    }
  }

  @media only screen and (max-width: 767px) {
    .item {
      margin-bottom: 0.25rem !important;
    }
  }
}

.ui.attached.segment {
  box-shadow: none !important;
  border-radius: none !important;
  border: none !important;
  margin: 0 !important;
  width: auto !important;
}

.account-upgrade {
  background: rgba(241, 141, 41, 0.74);
}

.icon-close {
  color: #979797;
  padding: 0.25rem;
}

.upgrade-text {
  padding-left: 1rem;
  font-style: normal;
  font-weight: 600;
  font-size: $Body-Large;
  line-height: 1.25rem;
  letter-spacing: -0.1.188rem;
  color: #3b3b3b;
}

.upgrade-text-sub {
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Default;
  line-height: 1.25rem;
  letter-spacing: -0.1.188rem;
  color: #3b3b3b;
  padding-top: 0.75rem;
  padding-left: 1rem;
}

.upgrade-button {
  height: 3.125rem !important;
  width: 169px !important;
  font-family: $fontFamily !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: $Body-Large !important;
  line-height: 1.563rem !important;
}

.sub-detail-container-verification {
  background-color: #fffaf9;
  padding: 1rem;
  border-radius: 0.313rem;
  margin-bottom: 1.5rem;
  width: 450px;

  .plan-include-text {
    font-family: $AuthPageFont;
    font-weight: 500;
    font-size: $Body-Default;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }

  .detail {
    margin-top: 0.313rem !important;
    display: flex !important;
    flex-direction: column !important;
    margin-left: 1.75rem !important;

    label {
      font-family: $AuthPageFontLight !important;
      font-size: $Body-Small !important;
      font-weight: 400 !important;
      line-height: 1.5rem !important;
      margin-right: 0.75rem;
    }

    .orangeCorrectIcon {
      color: #fd7057 !important;
    }

    .planList {
      font-family: $AuthPageFontLight;
      font-weight: 400;
      font-size: $Body-Small;
      line-height: 1.5rem;
    }
  }
}

.txt-button-align {
  display: flex;
  flex-direction: row;
  height: 100%;

  .upg-text-outline {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 4;
  }

  .btn-outline {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
  }
}

.upgrade-notification {
  height: 6.25rem;
  background-color: rgba(239, 129, 35, 0.1) !important;
  box-shadow: none !important;
}

@media only screen and (max-width: 767px) {
  .txt-button-align {
    flex-direction: column;
  }

  .upgrade-notification {
    height: auto;
  }

  .btn-outline {
    justify-content: center;
    margin-top: 1.25rem;
  }
}

.your-plan-subscription {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: 600;
  font-size: $Body-Default;
  line-height: 1.375rem;
  text-transform: uppercase;
  color: #3b3b3b;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.container-upgradeSubscription {
  margin: 0 0.625rem 0 0.625rem;
}

.detail {
  margin-top: 0.313rem !important;

  div {
    display: inline-flex !important;
  }

  label {
    font-family: $fontFamily !important;
    font-size: $Body-Small !important;
    font-weight: bold !important;
    line-height: 1.5rem !important;
  }
}

.dashboard-column {
  padding-left: 0px !important;
}

.to-do {
  background-color: $White;

  .todo-container {
    padding: 1.125rem $spacing-xl !important;
    display: flex;
    flex-direction: row;
    border-bottom: 0.5px solid #d6d6d6;
    cursor: pointer;

    &:hover {
      background-color: $Gray-20;
    }
  }

  .todo-container:last-child {
    border-bottom: none !important;
  }

  .todo-info-wrap {
    display: flex;
    flex-direction: column;
  }

  .todo-info-wrap-sub {
    display: flex;
    flex-direction: row;
  }

  .todo-segments {
    border: none !important;
    box-shadow: none !important;
    overflow: auto !important;
    height: 39.8rem !important;
  }

  .todo-container-sub {
    flex: 12;
  }

  .todo-info-date {
    @include Body-Small($Text-Secondary);
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 0rem;
  }

  .todo-info-description {
    @include Body-Small($Text-Secondary);
  }

  .todo-info {
    @include Body-Default();
    cursor: pointer;
  }

  .todo-action-container {
    flex: 1;

    .todo-action {
      background-color: #ffedde;
      color: #f2711c;
      -webkit-box-shadow:
        0 0 0 0.063rem #f2711c inset,
        0 0 0 0 transparent;
      box-shadow:
        0 0 0 0.063rem #f2711c inset,
        0 0 0 0 transparent;
      border-radius: 0.313rem;
      font-family: $fontFamily;
      font-size: $Body-Default;
      font-weight: 600;
      line-height: 1.375rem;
      cursor: pointer;
      margin: 0.625rem 0rem;
      padding: 0.313rem 0.625rem;
    }

    .todo-action-red {
      background-color: #ffe8e6;
      color: #db2828;
      -webkit-box-shadow:
        0 0 0 0.063rem #db2828 inset,
        0 0 0 0 transparent;
      box-shadow:
        0 0 0 0.063rem #db2828 inset,
        0 0 0 0 transparent;
      font-family: $fontFamily;
      border-radius: 0.313rem;
      font-size: $Body-Default;
      font-weight: 600;
      line-height: 1.375rem;
      cursor: pointer;
      margin: 0.625rem 0rem;
      padding: 0.313rem 0.625rem;
    }

    .todo-action-red:hover {
      color: $white;
      background-color: $red;
      border-radius: 0.313rem;
    }

    .todo-action:hover {
      color: $white;
      background-color: $gradientRight;
      border-radius: 0.313rem;
    }
  }

  .todo-action-container-custom {
    margin-top: 1.875rem;

    .todo-action {
      line-height: 1.375rem;
      cursor: pointer;
      margin: 0.625rem 0rem;
      padding: 0.313rem 0.625rem;
    }

    .todo-action-red {
      line-height: 1.375rem;
      cursor: pointer;
      margin: 0.625rem 0rem;
      padding: 0.313rem 0.625rem;
    }
  }

  .see-more-todo-text {
    display: flex !important;
    justify-content: flex-end !important;
    @include Body-Default($Primary);
    cursor: pointer !important;
    width: fit-content !important;
    float: right !important;
    padding: 1.2rem $spacing-sm;
  }
}

.notifications {
  .see-more-todo-text {
    display: flex !important;
    justify-content: flex-end !important;
    @include Body-Default($Primary);
    cursor: pointer !important;
    width: fit-content !important;
    float: right !important;
    padding: 0.625rem;
  }

  .notify-info {
    margin-bottom: 0.3rem !important;
  }
}
