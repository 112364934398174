.survey-modal {
  .ui.mini.modal {
    width: 320px !important;
    margin: 0 !important;
  }

  .content {
    padding: 1rem -0.1rem 1rem 0.5rem !important;
  }

  .canvas {
    width: 273px !important;
  }

  .ui.modal > .content {
    padding: 0.5rem !important;
  }
}
