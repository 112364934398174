@import '../../../mixins.scss';
@import '../../../variables.scss';

.ui.grid > .org-row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .account-sub-column {
    margin-top: 1.875rem;
    margin-bottom: 1.438rem;

    .form-group {
      margin-top: 1.25rem;
    }

    label {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Small;
      line-height: 1.063rem;
    }
  }
}

.subs-details {
  display: flex;
  flex-direction: column;

  .subs-header {
    font-size: $Body-Default !important;
    line-height: 1.375rem !important;
    font-weight: 600 !important;
    font-family: $fontFamily !important;
    color: black !important;
    text-align: left;
    padding-bottom: 1rem;
  }

  .subs-header-alert {
    font-size: $Body-Default !important;
    line-height: 1.375rem !important;
    font-weight: 600 !important;
    font-family: $fontFamily !important;
    color: red !important;
    text-align: left;
    padding-bottom: 1rem;
  }

  .subs-description {
    label {
      font-size: $Body-Default !important;
      line-height: 1.375rem !important;
      font-family: $fontFamily !important;
      text-align: left;
    }

    .ui.label {
      cursor: default;
    }
  }

  .subs-dec-card {
    font-size: $Body-Small !important;
    line-height: 1.188rem !important;
    font-family: $fontFamily !important;
    text-align: left;
  }
}

.subs-details-amount {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .subs-amount {
    font-size: $h4 !important;
    line-height: 2.75rem !important;
    font-weight: 600 !important;
    color: black !important;
    margin: 0;
  }
}

.subs-card-container {
  margin-left: 64px !important;
  margin-right: 0rem !important;
}

.card-details-container {
  display: flex;
  flex-direction: row;
  width: 100%;

  .card-owner {
    flex: 1;
    font-size: $Body-Default !important;
    line-height: 1.375rem !important;
    font-family: $fontFamily !important;
    text-align: left;
  }

  .card-number {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    label {
      font-size: $Body-Default !important;
      line-height: 1.375rem !important;
      font-family: $fontFamily !important;
      text-align: left;
      margin-bottom: 0 !important;
    }
  }
}

.subs-btn-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.25rem;
}

.ui.button.subscription {
  display: flex;
  align-items: center;
  height: 2.857rem;
  background-color: $gradientRight;
  color: white;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-weight: bold;
  line-height: 1.375rem;
  text-align: center;
}

.ui.button.subscription:hover,
.ui.buttons.subscription .button:hover {
  background-color: $amber;
  color: $white;
}

.ui.button.subscription:active {
  background-color: $gradientRight;
}

.ui.button.subscription-cancel {
  display: flex;
  align-items: center;
  height: 2.857rem;
  background-color: transparent;
  color: $red;
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-weight: bold;
  line-height: 1.375rem;
  text-align: center;
}

.sub-control-selector {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  margin-left: 5rem;
  margin-right: 5rem;

  .ui.grid {
    width: 100%;
  }

  .form-group {
    margin: 0;
  }

  .form-control {
    @include font-style(0.75rem !important);
    border: 0;
    padding: 0;
    opacity: 0.7;
  }

  .input-group {
    flex-wrap: nowrap;

    .input-group-prepend {
      padding-right: 0.5rem;
      padding-top: 0.5rem;
    }
  }
}

.invoice-table th,
.invoice-table td {
  border: none !important;
  color: $info;
  font-family: $fontFamily;
  font-size: $Body-Default;
  line-height: 1.375rem;
}

.invoice-table thead {
  tr {
    th {
      padding: 0.625rem 1.25rem !important;
    }
  }
}
.invoice-table tbody {
  tr {
    td {
      padding: 0.625rem 1.25rem !important;
      ul {
        padding-left: 0.938rem;
      }
    }
  }
}

.subscription-status {
  color: $red;
}
.subscription-status.pending {
  color: $gradientRight;
}
.subscription-status.paid {
  color: $green;
}

.ui.button.invoice {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  min-width: 136px;
  background-color: transparent;
  color: $gradientRight;
  border: 0.125rem solid $gradientRight;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  font-family: $fontFamily;
  font-size: $Body-Small;
  font-weight: bold;
  line-height: 1rem;
  text-align: center;
}

.ui.button.invoice:hover,
.ui.buttons.invoice .button:hover {
  background-color: $amber;
  color: $white;
}

.ui.button.invoice:active {
  background-color: $gradientRight;
}

.subscription-change-info {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(214, 0, 26, 0.1);
  height: 3rem;

  label {
    margin: 0;
    font-family: $fontFamily !important;
    font-size: $Body-Small !important;
    font-weight: bold !important;
    line-height: 1rem !important;
  }
}

.sub-inputs {
  height: 3.125rem !important;
}

.subs-form {
  padding: $spacing-xl !important;
}

.sub-detail-container {
  background-color: white;
  padding: 1rem;
  border-radius: 0.313rem;

  .detail {
    margin-top: 0.313rem !important;
    div {
      display: inline-flex;
    }
    label {
      font-family: $fontFamily !important;
      font-size: $Body-Small !important;
      font-weight: bold !important;
      line-height: 1.5rem !important;
    }
  }
}

.sub-detail-container-verification {
  background-color: #fffaf9;
  padding: 1rem;
  border-radius: 0.313rem;
  margin-bottom: 1.714285714rem;
  width: 450px;

  .plan-include-text {
    font-family: $AuthPageFont;
    font-weight: 500;
    font-size: $Body-Default;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }
  .detail {
    margin-top: 0.313rem !important;
    display: flex !important;
    flex-direction: column !important;
    margin-left: 1.75rem !important;

    label {
      font-family: $AuthPageFontLight !important;
      font-size: $Body-Small !important;
      font-weight: 400 !important;
      line-height: 1.5rem !important;
      margin-right: 0.75rem;
    }
    .orangeCorrectIcon {
      color: #fd7057 !important;
    }
    .planList {
      font-family: $AuthPageFontLight;
      font-weight: 400;
      font-size: $Body-Small;
      line-height: 1.5rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .subs-card-container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .card-details-container {
    flex-direction: column !important;
    .card-number {
      justify-content: flex-start;
      align-items: flex-start;
    }
  }

  .ui.button.subscription {
    height: auto !important;
  }

  .ui.button.subscription-cancel {
    height: auto !important;
  }

  .invoice-table {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .sub-control-selector {
    margin-left: 0;
    margin-right: 0;
  }

  .subscription-change-info {
    padding: 1.25rem;
  }

  .ui.grid > .org-row {
    margin-left: 2rem !important;
    margin-right: 2.563rem !important;
  }
}
.card-notification {
  height: 6.25rem;
  background-color: rgba(214, 0, 26, 0.1) !important;
  box-shadow: none !important;
  display: flex;
  align-items: center;
  .content {
    .header {
      font-family: $fontFamily !important;
      font-size: $Body-Large !important;
      font-weight: bold !important;
      line-height: 1.25rem !important;
      margin-bottom: 0.75rem;
      letter-spacing: -0.1.188rem;
      color: #3b3b3b;
    }
    p {
      font-family: $fontFamily !important;
      font-size: $Body-Default !important;
      line-height: 1.25rem !important;
      letter-spacing: -0.1.188rem;
      color: #3b3b3b;
    }
  }
}
@media only screen and (max-width: 767px) {
  .card-notification {
    height: auto;
    .content {
      .header {
        min-height: 2.857rem !important;
      }
    }
  }
}
.gst-view {
  font-family: $fontFamily !important;
  font-size: $Body-Small !important;
  line-height: 0.5rem !important;
  color: $infoText;
}

.gst-view {
  font-family: $fontFamily !important;
  font-size: $Body-Small !important;
  line-height: 0.5rem !important;
  color: $infoText;
}
