@import '../../variables.scss';
@import '../../mixins.scss';

.ui.raised.segment,
.ui.raised.segments {
  width: 100%;
}

.full-width {
  width: 100%;
}

.survey-header {
  display: flex;
  flex-direction: column;
  padding: 0;
  font-family: $fontFamily;

  width: 100%;
}

.survey-form {
  padding: 2% 1%;
}

.takso-logo {
  height: 40px;
  margin: 0px;
  cursor: pointer;
}

.whiteboxHeading {
  font-family: $fontFamily !important;
  font-size: $h6;
  font-weight: 1000;
  color: $white !important;
  font-family: $fontFamily;
  cursor: pointer;
}

.close-cancel-survey-btn {
  background-color: transparent !important;
  font-family: $fontFamily;
  font-weight: 600 !important;
  color: $gradientRight !important;
  border: 0.063rem solid $gradientRight !important;
}

.close-cancel-survey-btn:hover {
  background-color: $gradientRight !important;
  color: white !important;
}

.close-survey-btn {
  background-color: $gradientRight !important;
  color: $white !important;
}

.mainRow {
  background-color: $orgBackground;
  padding: 3% 5%;
  min-height: 100vh;
}

.profile-inputs {
  @include Body-Default;
  height: 3.125rem !important;
}

.notFoundSegment {
  width: 100%;
  .ui.grid {
    margin: 1rem 0rem;
  }
  button:focus {
    outline: 0 !important;
  }
}

.loaderSegment {
  width: 100%;
  height: 100vh;
}

.survey-center {
  justify-content: center !important;
}

.statement {
  margin: 0rem 0.938rem 1.875rem 0.938rem;
  @include Body-Small($info);
  text-align: justify;
}

.rc-slider-rail {
  background-color: #ececec !important;
}

.rc-slider-dot {
  background-color: #dee2e6 !important;
}

.rc-slider-track {
  background-color: $gradientRight !important;
}

.rc-slider-dot-active {
  background-color: $amber !important;
}

.rc-slider-mark-text {
  margin: 0.313rem 0;
  text-align: center;
  @include Body-Default-Semibold($Text-Secondary);
}

/* Media Query for Mobile Devices */
@media (max-width: 990px) {
  .rc-slider-mark-text {
    margin: 0.313rem 0;
    text-align: center;
    @include Body-Small($Text-Secondary);
  }
}

.survey-sub-header {
  background: #fad9bd;
  height: 90px;
}
