@import '../../variables.scss';
@import '../../mixins.scss';

.activity-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  label {
    flex: 1;
  }
  .square {
    min-height: 0.5rem !important;
    height: $spacing-sm;
    min-width: 0.5rem !important;
    width: $spacing-sm;
    border-radius: 0.188rem;
    margin-right: 0.625rem;
    margin-bottom: 0.313rem;
    background-color: $info;
  }
  .circle {
    min-height: 0.625rem;
    height: 0.625rem;
    min-width: 0.625rem;
    width: 0.625rem;
    border-radius: 0.625rem;
    margin-right: 0.625rem;
    margin-bottom: 2.188rem;
    background-color: $info;
  }

  .circle-bottom {
    margin-bottom: 0.375rem;
  }

  .circle-bottom-null {
    margin-bottom: 0.5rem !important;
  }

  .activity-completed {
    background-color: $blue;
  }
  .activity-success {
    background-color: $green;
  }
  .activity-warning {
    background-color: $amber;
  }
  .activity-error {
    background-color: $red;
  }
}
.sort-icon {
  display: flex;
  flex-direction: row;
}
.icon-adjustment {
  display: flex;
  align-items: center;
  margin-left: 0.625rem !important;
}
.up.icon.icon-adjustment {
  margin-top: 0.188rem;
}
.down.icon.icon-adjustment {
  margin-top: -0.125rem;
}

.Managed_Activities {
  i.ellipsis.vertical.icon {
    margin-left: $spacing-xl;
    margin-right: 0rem;
  }
  input.prompt {
    margin: 1rem 0rem 1rem 0rem !important;
  }

  .sub-child-segment {
    padding-right: $spacing-xl !important;
    padding-left: $spacing-xl !important;
  }
}
.Invited_Activities {
  i.ellipsis.vertical.icon {
    margin-right: 0rem;
  }

  input.prompt {
    margin: 1rem 0rem 1rem 0rem !important;
  }

  .sub-child-segment {
    padding-right: $spacing-xl !important;
    padding-left: $spacing-xl !important;
  }
}

.preview-div {
  .preview {
    @include font-style(1rem, $amber, 600);
  }
  .export {
    margin-left: 2.857rem;
    @include font-style(1rem, $green, 600);
  }
  label {
    cursor: pointer;
  }
}
