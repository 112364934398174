@import '../../variables.scss';
@import '../../mixins.scss';

.ui.raised.segment,
.ui.raised.segments {
  width: 100%;
}
.takso-logo {
  cursor: pointer;
}
.container-header {
  display: flex;
  flex-direction: column;
  padding: 0;
  font-family: $fontFamily;
}

.description-segment {
  min-height: 100vh;
  padding: 1.25rem 1.563rem !important;

  .activity-form {
    color: #3b3b3b !important;
  }

  p {
    font-size: $Body-Small;
    color: #3b3b3b !important;
    line-height: 1.5rem;
  }

  .link {
    color: $gradientRight !important;
  }

  .hr-padding {
    margin: 2.857rem 0 3.125rem 0;
  }

  .bold {
    font-weight: bold !important;
  }
}

.column-bar {
  padding-top: 1.563rem;
  border-top: 0.313rem solid #ff781d;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;

  .form-group > label {
    @include Body-Small($Text-Secondary);
  }

  .form-group > select {
    height: 3.125rem;
  }
}

.field-label {
  margin-bottom: 1.563rem;
}

.whiteboxHeading {
  font-size: $h6;
  font-weight: 1000;
  color: $white !important;
  margin: 2% 0% 2% 5% !important;
  font-family: $fontFamily;
}

.mainRow {
  background-color: $orgBackground;
  padding: 3% 5%;
  min-height: 100vh;
}

.notFoundSegment {
  width: 100%;
}

.statement {
  margin: 0rem 0.938rem 1.875rem 0.938rem;
  @include Body-Small($info);
  text-align: justify;
}

.qs-title {
  font-size: $Body-Large;
  font-weight: bold;
  margin-bottom: 0.938rem;
}

.qs-body {
  font-size: $Body-Small;
  font-weight: normal;
  color: #3b3b3b;
  line-height: 1.375rem;
  margin-bottom: 1.563rem;
  background-color: #f5f5f5;
  padding: 1rem $spacing-lg;
  border-radius: 0.313rem;
}

.column-padding {
  padding-left: 0.2rem !important;
  padding-right: 0.2rem !important;
}

.row-center {
  display: flex !important;
  justify-content: center !important;
}
