@import './variables';

@mixin button-style {
  background-color: $gradientRight;
  border: 0;

  &:hover {
    background-color: darken($gradientRight, 5%);
  }
}

@mixin search-field {
  .ui.input {
    width: 100%;

    .prompt {
      border-radius: 0.28571428rem;
    }
  }
}

@mixin search-field-custom {
  .ui.input {
    width: 100%;

    .prompt {
      border-radius: 0.571428571rem;
    }
  }
}

@mixin field-err {
  width: 100%;
  font-size: 80%;
  color: #ff0000;
  margin-top: 0.28571428rem;
}

// New Mixin

//when a default argument is given the parameter is optional
@mixin font-style(
  $font-size: $Body-Default,
  $color: $info,
  $font-weight: normal,
  $font-style: normal
) {
  font-family: $fontFamily;
  font-size: $font-size;
  color: $color;
  font-weight: $font-weight;
  font-style: $font-style;
}

@mixin h1($color: $black) {
  font-family: $fontFamily;
  font-size: $h1 !important;
  font-weight: bold;
  line-height: 5.857142857rem;
  color: $color;
}

@mixin h2($color: $black) {
  font-family: $fontFamily;
  font-size: $h2 !important;
  font-weight: bold;
  line-height: 4.428571429rem;
  color: $color;
}

@mixin h3($color: $black) {
  font-family: $fontFamily;
  font-size: $h3 !important;
  font-weight: bold;
  line-height: 3.428571429rem;
  color: $color;
}

@mixin h4($color: $black) {
  font-family: $fontFamily;
  font-size: $h4 !important;
  font-weight: 600;
  line-height: 2.857142857rem;
  color: $color;
}

@mixin h5($color: $black) {
  font-family: $fontFamily;
  font-size: $h5 !important;
  font-weight: 600;
  line-height: 2.285714286rem;
  color: $color;
}

@mixin h6($color: $black) {
  font-family: $fontFamily !important;
  font-size: $h6 !important;
  font-weight: 600;
  line-height: 1.857142857rem;
  color: $color;
}

@mixin Body-Large($color: $black) {
  font-family: $fontFamily !important;
  font-size: $Body-Large !important;
  font-weight: 400;
  line-height: 1.5rem;
  color: $color !important;
}

@mixin Body-Large-Semibold($color: $black) {
  font-family: $fontFamily !important;
  font-size: $Body-Large !important;
  font-weight: 600 !important;
  line-height: 1.5rem;
  color: $color;
}

@mixin Body-Default($color: $black) {
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 400 !important;
  line-height: 1.285714286rem;
  color: $color !important;
}

@mixin Body-Default-Semibold($color: $black) {
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 600 !important;
  line-height: 1.285714286rem !important;
  color: $color !important;
}

@mixin Body-Small($color: $black) {
  font-family: $fontFamily;
  font-size: $Body-Small !important;
  font-weight: 400 !important;
  line-height: 1.142857143rem !important;
  color: $color !important;
}

@mixin Body-Small-Semibold($color: $black) {
  font-family: $fontFamily;
  font-size: $Body-Small;
  font-weight: 600;
  line-height: 1.142857143rem;
  color: $color;
}

@mixin Button-Label($color: $black) {
  font-family: $fontFamily;
  font-size: $Body-Small;
  font-weight: 400;
  line-height: 1.142857143rem;
  color: $color;
}

@mixin Link-Text($color: $black) {
  font-family: $fontFamily;
  font-size: $Body-Small;
  font-weight: 400;
  line-height: 1.142857143rem;
  color: $color;
}

@mixin set-margin(
  $top: $spacing-lg,
  $right: 1rem,
  $bottom: $spacing-lg,
  $left: 1rem
) {
  margin: $top $right $bottom $left !important;
}

@mixin set-padding(
  $top: $spacing-xl,
  $right: $spacing-xl,
  $bottom: $spacing-xl,
  $left: $spacing-xl
) {
  padding: $top $right $bottom $left !important;
}

@mixin display-flex($justify-content: center, $align-items: center) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: $Primary;
  border-radius: 0.285714286rem !important;
  @include Body-Default($White);
  line-height: 1.285714286rem;
  padding: 0.625rem 1rem;
  border: 0.063rem solid $Primary;
  height: 2.857rem;

  &:hover {
    background-color: $Primary-dark;
    border-radius: 0.285714286rem;
  }

  &:active {
    background: $Primary;
    border: 0.063rem solid $Primary-light;
    border-radius: 0.285714286rem;
  }

  &:disabled {
    @include Body-Default($White);
    background: $Gray-50;
    border: 0.063rem solid $Gray-50;

    border-radius: 0.285714286rem;
  }
}

@mixin segment-header {
  padding: 1.1rem $spacing-xl !important;
}

@mixin segment-container {
  .row {
    margin: $spacing-lg $spacing-xl !important;
    column-gap: $spacing-lg !important;
  }
}

@mixin padding-vertical($padding: $spacing-lg) {
  padding: $padding 0rem !important;
}

@mixin padding-horizontal($padding: $spacing-lg) {
  padding: 0rem $padding !important;
}

@mixin padding-mix(
  $paddingVertical: $spacing-lg,
  $paddingHorizontal: $spacing-lg
) {
  padding: $paddingVertical $paddingHorizontal !important;
}

@mixin margin-vertical($margin: $spacing-lg) {
  margin: $margin 0rem !important;
}

@mixin margin-horizontal($margin: $spacing-lg) {
  margin: 0rem $margin !important;
}

@mixin margin-mix(
  $marginVertical: $spacing-lg,
  $marginHorizontal: $spacing-lg
) {
  margin: $marginVertical $marginHorizontal !important;
}
