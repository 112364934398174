@import '../../../../mixins.scss';
@import '../../../../variables.scss';

.msg-report {
  .ui.blue.message {
    background-color: #eff6ff !important;
    box-shadow: none !important;
    @include Body-Small(#1e40af);
    padding: 0.6rem;
  }

  .ui.icon.message > .icon:not(.close) {
    display: block;
    flex: 0 0 auto;
    width: auto;
    line-height: 1;
    font-size: $h6;
    opacity: 0.8;
    color: #002f9b !important;
  }

  border-radius: 0.375rem;
  background-color: red !important;
}
