@import './variables.scss';
@import './mixins.scss';

body {
  font-family: $fontFamily !important;
  padding-bottom: 40px;
}

::-webkit-scrollbar {
  width: 0.0001rem;
  background-color: $white !important;
}

::-webkit-scrollbar-thumb {
  background-color: $gradientRight !important;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ui.modal {
    top: 1% !important;
    left: 25% !important;
  }
}

.ui.modal {
  top: auto;
  left: auto;
  height: auto;
  .Primary-Button,
  .Secondary-Button {
    height: 2.286rem !important;
  }
}

.doubleHeaders {
  display: flex;
  flex-direction: row;
}

canvas {
  max-width: 100%;
}

.nav-icon-opacity {
  opacity: 0.8 !important;
}

.ui.modal > .trashicon {
  position: unset;
  color: black;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0.8;
  font-size: 1.25em;
  width: 2.25rem;
  height: 2.25rem;
  padding: 0.3rem 0 0 0;
  margin-top: 0.625rem;
  margin-right: 0.625rem;
}

.ui.modal > .closeicon {
  display: flex !important;
  justify-content: center !important;
  color: black !important;
  cursor: pointer !important;
  top: 0 !important;
  right: 0 !important;
  z-index: 1 !important;
  opacity: 0.8 !important;
  font-size: 1.25em !important;
  width: 2.25rem !important;
  height: 2.25rem !important;
  padding: 0.5rem 0 0 0 !important;
}

@media (min-width: 1624px) {
  .ui.modal > .closeicon {
    padding: 1.2rem 0.8rem 0 0 !important;
  }

  .ui.modal > .trashicon {
    padding: 0.625rem 0 0 0;
  }
}

input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: $spacing-md;
  height: $spacing-md;
  background-clip: content-box;
  border-radius: 50%;
  border: 0.125rem solid $Gray-40;
  cursor: pointer;
  padding: 0.125rem !important;
}

input[type='radio']:checked {
  border-color: $Primary;
  background-color: $gradientRight;
}

input[type='radio']:hover {
  border: 0.125rem solid $Primary !important;
  box-shadow: 0rem 0rem 0rem 2px #fad9bd;
}

input[type='checkbox'] {
  appearance: none;
  height: 1.142857143rem !important;
  width: 1.142857143rem !important;
  border: 0.125rem solid $Gray-40 !important;
  margin: 0rem !important;
}

input[type='checkbox']:checked::before {
  border: 0.125rem solid $Gray-40 !important;
}

input[type='checkbox']:checked:after {
  background: $Primary;
  content: '\2714';
  font-size: $Body-Default;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.286rem !important;
}

input[type='checkbox']:hover {
  border: 0.125rem solid $Primary !important;
  box-shadow: 0rem 0rem 0rem 2px #fad9bd;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

select {
  @include Body-Default;
}

.permission-form {
  .row {
    @include display-flex(center, center);
  }
}

.permission-form-custom {
  .row {
    @include display-flex(center, center);
  }
}

.table-custom-border {
  border: 1px solid $Gray-40;
  border-radius: 0.571rem;

  @include set-margin(0.5rem, 0.938rem, 2.051rem, 0.938rem);
  width: 100%;
}

.table-custom {
  border: none !important;
  border-radius: 0.571rem !important;
  thead {
    tr {
      th {
        background-color: #ffffff !important;
        border: none !important;
        border-radius: 0.5rem !important;
        padding-top: 1rem !important;
      }
      th:first-child {
        padding-left: 1rem !important;
      }
    }
  }

  tbody {
    tr {
      padding: 0rem !important;

      td {
        padding: 1rem 0rem 0rem 1rem !important;
        border: 0rem !important;
      }

      &:hover {
        background-color: transparent !important;
      }
    }
  }
}

.table-edit-custom {
  margin-top: 0.5rem !important;
  margin-left: 2.375rem !important;
  margin-right: 2.375rem !important;
  border-radius: 0.5rem !important;

  thead {
    tr {
      th {
        background-color: #ffffff !important;
        border: 0.031rem solid rgba(0, 0, 0, 0) !important;
        padding-top: 1rem !important;
        padding-left: 0.625rem !important;
        border-top-left-radius: 0.5rem !important;
        border-top-right-radius: 0.5rem !important;
      }
    }
  }

  tbody {
    tr {
      padding: 0rem !important;

      td {
        padding: 1rem 0rem 0rem 1rem !important;
        border: 0rem !important;
      }
    }
  }
}

.table-edit-custom-participant {
  border-radius: 0.5rem !important;

  thead {
    tr {
      th {
        background-color: #ffffff !important;
        padding-top: 1rem !important;
        padding-left: 0.625rem !important;
        border-top-left-radius: 0.5rem !important;
        border-top-right-radius: 0.5rem !important;
      }
    }
  }

  tbody {
    tr {
      padding: 0rem !important;

      td {
        padding: 1rem 0rem 0rem 1rem !important;
        border: 0rem !important;
      }
    }
  }
}

// semantic-ui date selector style
.ui.celled.unstackable.center.aligned.table {
  thead,
  tbody {
    tr {
      th {
        padding: 0.625rem !important;
      }

      td {
        padding: 0.625rem !important;
      }
    }
  }
}

// semantic-ui date selector style
.daterangepicker {
  thead,
  tbody {
    tr {
      th {
        padding: 0.313rem 0.625rem !important;
        background-color: white !important;
      }

      td {
        padding: 0.313rem 0.625rem !important;
      }
    }
  }
}

.permission-pop-up {
  padding: 0rem 1.25rem !important;
  margin: 0.625rem !important;
  background-color: $white;
  border-radius: 0.625rem;

  &::before {
    content: none !important;
  }

  .table {
    border: 0 !important;

    thead,
    tbody {
      background-color: transparent !important;

      tr {
        th {
          border: 0 !important;
          background: transparent;
          padding: 0.5rem 0;
          @include font-style(0.75rem);
        }

        td {
          border: 0 !important;
          padding: 0.313rem 0 !important;
          vertical-align: middle;
          @include font-style(1rem);

          fieldset {
            margin: 0;
          }

          label {
            @include font-style(1rem);
          }
        }
      }
    }
  }

  .permission-form {
    .row {
      padding: 0 !important;
    }

    .permission-first-row {
      .form-check {
        label {
          @include font-style(1rem);
        }
      }
    }

    .form-check-label {
      font-size: $Body-Default !important;
    }
  }
}

.permission-pop-up-custom {
  padding: 0rem 1.25rem !important;
  margin: 0.625rem !important;
  background-color: #fafafa !important;
  border-radius: 0.625rem;

  &::before {
    content: none !important;
  }

  .table {
    thead,
    tbody {
      background-color: transparent !important;

      tr {
        th {
          border: 0 !important;
          background: transparent;
          padding: 0.5rem 0;
          @include font-style(0.75rem);
        }

        td {
          border: 0 !important;
          padding: 0.313rem 0 !important;
          vertical-align: middle;
          @include font-style(1rem);

          fieldset {
            margin: 0;
          }

          label {
            @include font-style(1rem);
          }
        }
      }
    }
  }

  .permission-form {
    .row {
      padding: 0 !important;
    }

    .permission-first-row {
      .form-check {
        label {
          @include font-style(1rem);
        }
      }
    }

    .form-check-label {
      font-size: $Body-Default !important;
    }
  }
}

.fMbuBM {
  right: 1% !important;
  bottom: 2% !important;
  padding: 0rem !important;
  border: 0rem !important;
  background: transparent !important;
}

.fMbuBM:hover {
  color: #ef8123 !important;
  background: transparent !important;
}

.scrolltop-custom {
  font-size: $h6 !important;
  color: #3b3b3b;
  margin-right: -0.125rem !important;
}

.scroll-your-role {
  border: none !important;
  padding: 0rem !important;

  i.icon {
    display: initial !important;
    margin: 0rem !important;
  }
}

.scroll-your-role:hover {
  background-color: $Gray-50 !important;
}

.pusher {
  background-color: $background !important;
}

tfoot {
  //sets the pagination style in the table footer
  .ui.secondary.pointing.menu {
    border-bottom: 0;

    a {
      &.item {
        background-color: #f3f3f3;
        color: #000000;
        border-radius: 3.125rem;
        border: 0;
        padding: 0.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 1rem;

        &.active {
          border: 0;

          &:hover {
            background-color: #ef8123;
            color: #fff;
          }

          background-color: #ef8123;
          color: #fff;
        }

        &:hover {
          background-color: #f3f3f3;
        }
      }
    }
  }
}

.notification-message {
  .title {
    color: white;
  }
}

canvas {
  max-width: 100%;
}

//New Styles
html {
  font-size: 14px !important;
  font-style: $fontFamily !important;
}

//headers
h1.ui.header {
  @include h1;
}

h2.ui.header {
  @include h2;
}

h3.ui.header {
  @include h3;
}

h4.ui.header {
  @include h4;
}

h5.ui.header {
  @include h5;
}

h6.ui.header {
  @include h6;
}

.Body-Large-Semibold {
  @include Body-Default-Semibold;
}

//segment

.ui.raised.segments {
  .segment-title {
    display: flex;
    align-items: center;
  }

  .ui.segment,
  .ui.grid {
    padding: 0em;
    margin: 0em;
  }

  .ui.grid > .row > .column {
    padding: 0em;
    margin: 0em;
  }

  .custom-segment {
    @include segment-header;
  }

  .ui.section.divider {
    margin: 0rem !important;
    padding: 0rem;
  }

  border: 0.5px solid #d6d6d6 !important;
}

//padding
.pt-0 {
  padding-top: 0rem;
}

.pb-0 {
  padding-bottom: 0rem;
}

.pl-0 {
  padding-left: 0rem;
}

.pr-0 {
  padding-right: 0rem;
}

//margin
.mt-0 {
  margin-top: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.ml-0 {
  margin-left: 0rem;
}

.mr-0 {
  margin-right: 0rem;
}

.p-0 {
  padding: 0rem !important;
}

.m-0 {
  margin: 0rem !important;
}

//Button
.ui.button.close-valid-btn {
  @include btn;
  background: $Success;
  border: 0.063rem solid $Success;

  &:hover {
    background: $Success-dark !important;
  }

  &:disabled {
    background: $Success !important;
    border: 0.063rem solid $Success !important;
  }
}

.ui.button.close-reopen-btn {
  @include btn;
  background: $Info;
  border: 0.063rem solid $Info;

  &:hover {
    background: $Info-dark !important;
  }

  &:disabled {
    background: $Info !important;
    border: 0.063rem solid $Info !important;
  }
}

.ui.disabled.button.close-button {
  @include btn;
}

.ui.button.Primary-Button {
  @include btn;
}

.ui.button.Secondary-Button {
  @include btn;
  background: $white;
  color: $Primary !important;

  &:hover {
    background-color: $Primary-lighter !important;
    color: $Primary !important;
  }

  &:active {
    color: $Primary !important;
    border: 0.063rem solid $Primary;
  }

  &:disabled {
    @include Body-Default($Gray-50);
    background: $White;
    border: 0.063rem solid $Gray-50;

    border-radius: 0.25rem;
  }
}

.Primary-Button {
  @include btn;

  i.icon {
    display: flex;
    align-items: center;
  }
}

.Secondary-Button {
  @include btn;
  background: $white;
  color: $Primary !important;

  &:hover {
    background-color: $Primary-lighter !important;
    color: $Primary !important;
  }

  &:active {
    color: $Primary !important;
  }

  i.icon {
    display: flex;
    align-items: center;
  }
}

.Secondary-Button:hover {
  cursor: pointer;
}

.create-button {
  background: #ffffff;
  border: 0.0625rem solid #ef8123;
  box-sizing: border-box;
  border-radius: 0.25rem;
  text-align: center;
  color: #ef8123;
  width: 15.8125rem;
  height: 2.857rem;
  cursor: pointer;

  .icon-new {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.4375rem 0rem 0rem 1.25rem;
  }

  .add-eval-text {
    padding: 0rem 0rem 0rem 0.625rem !important;
    font-style: normal;
    font-weight: normal;
    font-size: $Body-Default;
    line-height: 1.5rem;
  }
}

.footer-buttons {
  display: flex;
  float: right;
}

.required-star-label:after {
  content: ' *';
  color: red;
}

//Input type text
input[type='text'],
input[type='email'],
input[type='password'],
input[type='url'],
input[type='number'],
select,
input#phone {
  @include Body-Default($Black);
  line-height: 1.25rem !important;
  border-color: #c3d3e6 !important;
  height: 2.857rem !important;
  border-radius: 0.5rem !important;

  min-height: 2.857rem !important;

  &:focus {
    border: 0.031rem solid #ef8123 !important;
    box-shadow: 0rem 0rem 0rem 0.125rem #fad9bd !important;
  }
}

.date-range-container {
  label {
    display: flex !important;
  }
}

.formik-custom-field {
  .dropdown {
    min-height: 2.857rem !important;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
    border-color: #c3d3e6 !important;
    border-radius: 0.5rem !important;

    &:focus {
      border: 0.031rem solid #ef8123 !important;
      box-shadow: 0rem 0rem 0rem 0.125rem #fad9bd !important;
    }

    .text {
      // @include Body-Default($Black);
      line-height: 1rem !important;
    }
  }
  .ui.dropdown:not(.button) > .default.text {
    color: $Black;
  }

  .ui.multiple.dropdown > .text {
    margin: 0.08238095em 0 0.08238095em 0.64285714em !important;
  }
}

.formik-custom-multiple-field {
  .dropdown {
    min-height: 2.857rem !important;
    padding-top: 11px !important;
    padding-bottom: 11px !important;
    border-color: #c3d3e6 !important;
    border-radius: 0.5rem !important;

    &:focus {
      border: 0.031rem solid #ef8123 !important;
      box-shadow: 0rem 0rem 0rem 0.125rem #fad9bd !important;
    }

    .text {
      @include Body-Default($Black);
      line-height: 1rem !important;
    }
  }

  .ui.selection.active.dropdown .menu {
    border-color: transparent !important;
    box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  }

  .ui.selection.dropdown .menu {
    max-height: 18.02857143rem;
  }

  .ui.multiple.dropdown > .text {
    margin: 0.08238095em 0 0.08238095em 0.64285714em !important;
  }

  .text-empty-info {
    color: $Error !important;
    margin: 0.08238095em 0 0.08238095em 1.042857em !important;
  }
}

textarea {
  @include Body-Default($Black);
  line-height: 1.25rem !important;
  background: #ffffff !important;
  border-color: #c3d3e6 !important;
  padding: 0.625rem 1rem 0.625rem 1rem !important;
  border-radius: 0.5rem !important;

  &:focus {
    border: 0.031rem solid #ef8123 !important;
    box-shadow: 0rem 0rem 0rem 0.125rem #fad9bd !important;
  }
}

//Pagination
.ui.tiny.pagination.menu {
  box-shadow: none !important;
  border: none !important;

  .item {
    position: initial !important;
    border: 0.0625rem solid #dfe3e8 !important;
    color: #000000 !important;
    border-radius: 0.25rem !important;
    padding: 0.625rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0rem 0.25rem !important;

    &.active {
      border: none !important;
      border: 0.0625rem solid $Primary !important;
      color: $Primary !important;
      background-color: $white !important;
    }

    &:hover {
      background-color: $Primary-lighter !important;
      border: 0.0625rem solid $Primary !important;
      color: $Primary !important;
    }
  }
}

.table-pagination {
  .item {
    border: 0.0625rem solid $Primary !important;

    color: $Primary !important;
    border-radius: 50% !important;

    &.active {
      border: 0 !important;

      &:hover {
        background-color: $Primary !important;
        color: $white !important;
      }

      background-color: $Primary !important;
      color: $white !important;
    }
  }
}

.ui.raised.segments {
  .row,
  .column {
    padding: 0rem;
    margin: 0rem;
  }
}

input[type='checkbox']:checked::after {
  background-color: $Primary !important;
}

input[type='checkbox']:checked {
  border: $white !important;
}

input[type='checkbox'] {
  -moz-appearance: initial !important;
}

table {
  margin: 0rem !important;
  border-collapse: collapse !important;

  .ui.segment {
    background: none !important;
  }

  th {
    @include Body-Default-Semibold($Gray-70);
    background-color: $Gray-10 !important;

    padding: 1rem !important;
  }

  td {
    @include Body-Default;
    border: none !important;
    padding: 1rem !important;
  }

  th:first-child,
  td:first-child {
    padding-left: $spacing-xl !important;
  }

  th:last-child,
  td:last-child {
    padding-right: $spacing-xl !important;
  }

  tbody {
    th:first-child,
    td:first-child {
      @include Body-Default;
    }

    tr {
      padding: 0.063rem 0.063rem 0.063rem 0.063rem !important;

      &:hover {
        background-color: $Gray-20;
        color: $Primary;
      }
    }
  }

  tfoot {
    th {
      @include padding-vertical;
    }
  }
}

.ui.segment.custom-segment {
  @include Body-Default-Semibold;
}

.issues-box {
  li {
    @include Body-Default($Error);
  }
}

.text-muted {
  @include Body-Small($Text-Secondary);
}

.text-error {
  @include Body-Small($Error);
}

.table-edit-tag {
  @include Body-Default($Primary-dark);
  cursor: pointer;
}

.table-delete-tag {
  @include Body-Default($Error-dark);
  cursor: pointer;
}

.notification-segment {
  background: $Error-light;
  margin-bottom: 1rem !important;

  span {
    @include Body-Default($Error);
  }

  .ui.message {
    border: 0.031rem solid $Error;
    border-radius: 0.5rem;
  }

  i.close.icon {
    color: $Error;
    margin-top: 0.375rem !important;
  }
}

.input-label {
  @include Body-Default;
}

.date-range-container {
  input[type='text'] {
    @include Body-Default;

    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }

  .date-range-chart {
    border-color: #c3d3e6 !important;
  }
}

.calendar-table {
  table {
    th {
      font-size: $Body-Small !important;
    }

    td {
      font-size: $Body-Small !important;
    }

    td:first-child {
      font-size: $Body-Small !important;
    }

    select {
      border: none !important;
      font-size: $Body-Default !important;

      &:focus {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
}

@media only screen and (min-width: 564px) {
  .ranges {
    li {
      font-size: $Body-Small !important;
    }
  }
}

.button-flex {
  display: flex;
  justify-content: flex-end;
}

.billing-and-invoices {
  .content,
  .segment {
    padding: 0px !important;
  }
}

.h1 {
  @include h1;
}

.h2 {
  @include h2;
}

.h3 {
  @include h3;
}

.h4 {
  @include h4;
}

.h5 {
  @include h5;
}

.h6 {
  @include h6;
}

.body-large-regular {
  @include Body-Large;
}

.body-large-semibold {
  @include Body-Large-Semibold;
}

.body-default-regular {
  @include Body-Default;
}

.body-default-semibold {
  @include Body-Default-Semibold;
}

.body-small-regular {
  @include Body-Small;
}

.body-small-semibold {
  @include Body-Small-Semibold;
}

.button-label {
  @include Button-Label;
}

.link-text {
  @include Link-Text;
}

.mt-8 {
  margin-top: 0.571rem;
}

.mr-16 {
  margin-right: 1.143rem;
}

.mb-24 {
  margin-bottom: 1.714rem;
}

.mt-24 {
  margin-top: 1.714rem;
}

.ml-24 {
  margin-left: 1.714rem;
}

.mr-24 {
  margin-right: 1.714rem;
}

.mr-16 {
  margin-right: 16px;
}

.display-flex {
  @include display-flex;
}
.reference-mainText.item {
  margin-bottom: 0.571 !important;
}

span.show-more-less-clickable:hover {
  text-decoration: underline !important;
}

.pre-line {
  white-space: pre-line;
}

.text-secondary {
  color: $Text-Secondary;
}
.model-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.fw-bold {
  font-weight: bold;
}

.daterangepicker .drp-calendar.left .calendar-table {
  padding-right: 0rem !important;
}

.formik-error-msg {
  margin-top: 0.25rem;
  font-size: 80%;
  color: $red;
}

.error-msg {
  margin-top: 0.25rem;
  font-size: 80%;
  color: $red;
}

.w-85 {
  width: 85%;
}

.formik-custom-field .dropdown:focus {
  border: 0.031rem solid #ef8123 !important;
  box-shadow: 0rem 0rem 0rem 0.125rem #fad9bd !important;
}
.ui.inline.dropdown .menu .item:hover {
  background-color: $Primary-lighter !important;
}
.ui.inline.dropdown .menu .item.selected {
  background-color: $gradientRight !important;
  color: $white !important;
}

.btn-gradientRight {
  background-color: transparent !important;
  border: 0.063rem solid $gradientRight !important;
  color: $gradientRight !important;
  font-family: $fontFamily !important;
  font-weight: 400 !important;
}

.info-fs-1 {
  $font-size: $Body-Default !important;
  $color: $info !important;
  $font-weight: normal;
  $font-style: normal;
}

.cursor-disabled {
  cursor: not-allowed !important;
}

.subLabel {
  color: $subLabel !important;
}

.formik-multiple-dropdown-error {
  margin-top: 0.25rem !important;
  @include Body-Small($Error);
}

.infoText {
  color: $infoText;
  font-family: $AuthPageFontLight;
  font-size: $Body-Large;
  line-height: 1.75rem;
}

.dropdown-outline {
  border: 1px solid black;
  padding: 0px 10px;

  .icon {
    float: right;
    top: -5px !important;
  }
}

.btn-include-in-report {
  background-color: transparent !important;
  font-family: $fontFamily !important;
  font-weight: 400 !important;
}

.sidebar-pusher-container {
  max-height: 100vh !important;
  overflow-y: auto !important;
}

.sidebar-pusher-container-program {
  overflow-y: auto !important;
  overflow: visible !important;
}

.ui.visible.wide.right.sidebar.advanced-filter ~ .pusher {
  transform: translate3d(-300px, 0, 0) !important;
}

.user-email-verification {
  background-color: #ccdcfb !important;
  grid-template-columns: 1fr auto 1fr;
  height: $spacing-2xl;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10000;
  font-weight: 400 !important;

  .email-verification-lbl {
    font-size: $Body-Small !important;
    font-weight: 400;
    text-align: center;
  }

  .verify-btn {
    font-size: $Body-Small !important;
    font-weight: 500;
    border-bottom: 1.5px solid #1e40af;
    color: #1e40af !important;
    cursor: pointer;
  }

  .dismiss-btn {
    font-size: $Body-Small !important;
    color: $info !important;
    font-weight: 500;
    cursor: pointer;
  }
}

.ui.checkbox {
  backface-visibility: visible !important;
  -webkit-backface-visibility: visible !important;
}

.modal {
  overflow: visible !important;
}

// report font size inject global (quick change)
.manual-chart-font-size {
  font-size: 24px !important;
}
