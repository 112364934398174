@import '../../variables.scss';
@import '../../mixins.scss';

.custom-tabs {
  width: inherit;

  .ui.segment {
    box-shadow: none;
    border-radius: none;
    border: none;
  }

  .ui.menu.borderless {
    border: none;
    box-shadow: none;

    .item {
      flex: 1 !important;
      display: flex !important;
      flex-wrap: wrap !important;
      justify-content: center !important;
      @include Body-Default-Semibold($info);
      background-color: $orgBackground !important;
      border-right: 0.063rem solid $background !important;
      padding: 0 !important;
      text-align: center !important;
    }

    .item.active {
      background-color: $white !important;
      padding: 0 !important;
      color: $gradientRight !important;
    }
  }
}

.radio-reassign {
  .form-control {
    background-color: transparent;
  }
}

.ui.borderless.menu {
  margin-bottom: $spacing-lg !important;
}

.custom-tabs-mobile {
  width: inherit;

  .ui.borderless.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .item {
    width: -webkit-fill-available;
    display: flex !important;
    justify-content: center !important;
    color: $info !important;
    font-family: $fontFamily !important;
    font-size: $Body-Small !important;
    font-weight: 600 !important;
    line-height: 1.188rem !important;
    background-color: $background !important;
  }

  .item.active {
    background-color: $white !important;
    color: $gradientRight !important;
  }
}

.ui.tab.active.custom-tab {
  padding: 0 !important;
  background-color: $background !important;
}

.gap-between-windows {
  margin-top: 3.125rem;
}

.custom-error-label {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.custom-error-input .prompt {
  border-color: #dc3545 !important;
}

.inputSearch {
  width: 100% !important;

  .ui.icon.input {
    width: 100% !important;
    height: 2.25rem !important;
    font-size: $Body-Small !important;

    input {
      border-radius: 0.5rem;
    }
  }

  .results {
    max-height: 400px;
    overflow: auto;
  }

  .results.transition.visible {
    width: 100% !important;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media only screen and (max-width: 767px) {
  .inputSearch {
    width: 100%;
    height: 2.25rem !important;
    font-size: $Body-Small !important;
  }
}

.organization-settings-tab {
  padding: 0rem !important;
}

.evidence-table {
  padding: 26px 0rem;
  background-color: $white;

  th,
  td {
    vertical-align: inherit !important;
  }

  thead tr th b {
    padding-left: $spacing-xl;
  }

  .evidence-container {
    display: flex !important;
    flex-direction: column !important;

    .ui.label {
      background-color: $white;
      font-family: $fontFamily;
      font-style: normal;

      font-weight: normal;
      font-size: $Body-Small;
      line-height: 1.25rem;
      color: $black;
      padding: 0;
    }
  }

  .evidence-mainText {
    color: $black !important;
    font-family: $fontFamily !important;
    font-size: $Body-Small !important;
    line-height: 1.25rem !important;
    font-style: normal !important;
    font-weight: normal !important;
    padding: 0rem;
  }

  .evidence-text {
    color: $info !important;
    font-family: $fontFamily !important;
    font-size: $Body-Small !important;
    font-weight: 300 !important;
    line-height: 22px !important;
  }

  .evidence-text-container {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
  }

  .evidence-actions {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
  }

  .cursor {
    cursor: pointer;
  }

  .evidence-actions-warn {
    color: $gradientRight !important;
    font-family: $fontFamily !important;
    font-size: $Body-Small !important;
    font-weight: normal !important;
    line-height: 1.25rem !important;

    .cursor-pointer {
      cursor: pointer !important;
    }
  }

  .evidenceSearch {
    width: 65% !important;
  }

  .ui.input > input {
    .ui.search .prompt {
      border-radius: 0rem;
    }
  }

  .ui.icon.input {
    width: 100% !important;

    input {
      border-radius: 0.25rem;
    }
  }
}

eval-bg-new {
  background-color: #ffffff !important;

  .ui.table {
    margin: 0rem !important;
  }

  .ui.table thead {
    top: 0;
  }

  th:nth-child(2),
  th:nth-child(3) {
    padding-left: 0rem !important;

    b {
      padding-left: 0.625rem !important;
    }
  }
}

.org-goals-table th,
.org-goals-table td {
  border-top: none !important;
  border-bottom: 0.063rem solid rgba(0, 0, 0, 0.1) !important;
}

.org-goals-table tfoot .ui.secondary.pointing.menu a.item.active {
  border: 0 !important;
  background-color: #ef8123 !important;
  color: #fff !important;
}

.org-ref-table th {
  padding: 0.625rem 1rem 0.625rem 34px !important;
  border-top: 0.063rem solid rgba(0, 0, 0, 0.1) !important;
}

.org-ref-table td {
  border-bottom: 0.063rem solid rgba(0, 0, 0, 0.1) !important;
  padding: 1rem $spacing-xl 18px $spacing-xl !important;

  label {
    margin-bottom: 0.571rem !important;
  }

  .reference-mainText.item,
  label.core-data-label {
    display: list-item;
  }

  .reference-mainText.item,
  .reference-mainText-muted.item {
    margin-bottom: 0.571rem;
    display: list-item;
  }
  padding-bottom: 0.642rem !important;
}

td.left.aligned.top.aligned.seven.wide {
  padding-left: $spacing-xl !important;
}

.view-more-btn {
  margin-left: -1rem;
  margin-top: 0.25rem;
}

.org-ref-table tr {
  padding: 0.063rem 0.063rem 0.063rem 0.063rem !important;
}

.org-ref-table thead {
  background-color: #fafafa;
}

.tbody-hover tbody tr:hover {
  background-color: #f3f4f6;
}

.org-ref-table-items {
  text-align: center !important;
}

.org-ref-table-team td,
.org-ref-table-team tr {
  padding: 0.75rem 40px !important;
  font-family: 'Inter', sans-serif;
  font-size: $Body-Default;
  color: #3b3b3b;
  font-weight: normal;
  font-style: normal;
}

// references style
.reference-header {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: $Body-Default;
  line-height: 1.5rem;
  letter-spacing: -0.063rem;
  color: #3b3b3b;
}

.action-container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end;

  .ui.label {
    background-color: $white;
    font-family: $fontFamily;
    font-style: normal;

    font-weight: normal;
    font-size: $Body-Small;
    line-height: 1.25rem;
    color: $black;
    padding: 0;
  }

  .ref-edit-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: $Body-Small;
    line-height: 1.25rem;
    display: flex;
    align-items: center;
    color: #ef8123;
    justify-content: flex-end;
    cursor: pointer;
  }

  .ref-delete-label {
    @include Body-Default($red);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }
}

.reference-mainText-muted {
  @include Body-Default($Gray-70);
  padding: 0rem;
}

.reference-text {
  color: $info !important;
  font-family: $fontFamily !important;
  font-size: $Body-Small !important;
  font-weight: 300 !important;
  line-height: 22px !important;
}

.reference-text-container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-evenly !important;
}

.reference-actions {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-evenly !important;
}

.archived-lbl {
  padding: 6px 0.75rem;
  gap: 0.625rem;
  /* Neutral/ Gray-20 */
  background: #f5f5f5;
  border-radius: 0.25rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Small;
  line-height: 1.25rem;
  /* identical to box height, or 200% */
  text-align: center;
  /* Neutral/ Gray-70 */
  color: #757575;
}

.ref-plans-show {
  .add-new-ref {
    display: flex;
    flex-direction: row-reverse;
  }
}

.ui.button.btn-add-ref {
  background: #ffffff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Small;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  color: #ef8123;
}

.ui.button.plus-icon {
  background: #ffffff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  size: 0.857142857rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  color: #ef8123;
}

.btn-add-ref {
  background: #ffffff;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: $Body-Small;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  color: #ef8123;
}
.add-button-text {
  margin-left: 0.5rem;
  display: inline-block;
}

.add-new-checklist-item {
  .header {
    @include Body-Default-Semibold;
  }

  label {
    @include Body-Small($Text-Primary);
  }
}

.add-new-venue,
.update-Venue {
  .venue-modal-buttons {
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem;
  }

  label {
    @include Body-Default;
  }

  input[type='text'] {
    @include Body-Default;
  }

  .ui.input > input {
    @include Body-Default;
  }

  .venue-type {
    .venue-type-des {
      @include Body-Small($Text-Secondary);
    }
  }

  .ui.dropdown .menu > .header {
    font-size: $Body-Small !important;
    font-weight: 700 !important;
    color: $black !important;
  }

  .sub-header {
    color: $Gray-50 !important;
    font-size: $Body-Small !important;
    margin-left: 0rem !important;
  }

  .content {
    padding: 0rem !important;
  }

  .form-content {
    padding: 1.5rem;
  }
  .ui.section.divider {
    margin: 0rem !important;
  }
  .venue-address-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1.25rem !important;
  }
  .location-search-max-width {
    width: 100%;
  }
}

.delete-venue,
.delete-contractor {
  .actions {
    display: flex;
    justify-content: flex-end;
  }

  .Primary-Button {
    margin-left: 0.25rem !important;
  }
}

.contact-modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.model-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add-unit-label {
  color: $Primary;
  cursor: pointer;
}

.info-blue-fs-1 {
  $font-size: $Body-Default !important;
  $color: $Info !important;
  $font-weight: normal;
  $font-style: normal;
}

.sub-option-check {
  margin: 0px 30px 5px !important;
}
