@import '../../variables.scss';
@import '../../mixins.scss';

.box-width {
  width: 95%;
  margin-top: 2.188rem;
}

.edit-outcome-status-bar {
  margin-top: $spacing-lg;
  display: flex;
  flex-direction: row;

  .toggle-left {
    margin-left: 0.625rem;
  }
}
.all-export-download-padded {
  display: flex;
  flex-direction: row;
  padding: 2.857rem 0 2.857rem 0rem;
  background-color: white !important;
  justify-content: space-between;
}

.organisation-dropdown {
  width: 350px;

  .text {
    line-height: 1rem !important;
  }
  .ui.dropdown:not(.button) > .default.text {
    color: #f2711c;
  }
  .ui.selection.dropdown {
    border-color: $Primary !important;
  }
  .ui.search.dropdown > .text {
    color: #f2711c;
  }
}

.data-export-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 8px;
  background-color: $white;
  .form-text {
    @include font-style(1rem, $info, 500);
  }
  &:hover {
    background-color: $Primary-lighter;
  }
}
// .org-block {
//   padding: 0.25rem ​0.313rem 0.375rem 0.063rem;
// }

.custom-segment {
  .export-button-admin {
    margin: 0rem !important;
    padding: 0.625rem !important;
    background-color: $gradientRight !important;
    @include font-style(1rem !important, $white !important, 600);

    .ui.dropdown > .left.menu {
      top: 29px;
      left: -5.5rem !important;
    }

    .ui.dropdown {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      height: 100% !important;
    }

    .ui.ui.dropdown .menu > .item:hover {
      background-color: $background !important;
    }
  }

  .loading-btn {
    background-color: #fff !important;
  }

  .export-button-admin-users {
    margin-right: 0.625rem !important;
    padding: 0.625rem !important;
    background-color: $gradientRight !important;
    min-width: 6.25rem !important;
    height: 2rem !important;
    @include font-style($spacing-sm !important, $white !important, 500);

    .ui.dropdown > .left.menu {
      top: 29px;
      left: -5.5rem !important;
    }

    .ui.dropdown {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100% !important;
      height: 100% !important;
    }

    .ui.ui.dropdown .menu > .item:hover {
      background-color: $background !important;
    }
  }

  .loading-btn {
    background-color: #fff !important;
  }
}

.advance-toggle-org {
  @media only screen and (max-width: 767px) {
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;

    label {
      flex: 6 !important;
    }
  }

  label {
    @include font-style(1rem !important, $info);
    flex: 2;
  }

  .ui.fitted.toggle.checkbox {
    flex: 1/3;
  }

  .ui.toggle.checkbox {
    label {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
    }
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }
}

.less-padding {
  .outcome-admin {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem !important;
  }
}

.outcome-admin-types {
  display: flex;
  flex-direction: row;
  padding: $spacing-lg $spacing-xl;
  background-color: white;
  justify-content: flex-end;

  .outcomes-add {
    font-size: $Body-Default;
    margin-top: 0.5rem;
  }

  .button-outcome {
    margin-right: $spacing-lg;
  }

  .submit-button {
    cursor: pointer;
    height: 2.857rem;
    background-color: $gradientRight;
    @include button-style;
    border: none;
    border-radius: 0.313rem;
    @include font-style(1rem, $white, normal);
    @include set-padding(0.563rem, 2.563rem, 0.563rem, 2.563rem);

    &:focus {
      outline: none !important;
    }
  }

  .submit-button-custom {
    cursor: pointer;
    height: 2.857rem;
    background-color: $gradientRight;
    @include button-style;
    border: none;
    border-radius: 0.313rem;
    @include font-style(1rem, $white, bold);
    @include set-padding(0.563rem, 2.563rem, 0.563rem, 2.563rem);
  }
}

.outcome-admin {
  display: flex;
  flex-direction: row;
  padding: $spacing-lg 0.5rem $spacing-lg $spacing-xl;
  background-color: white;
  justify-content: flex-start;

  .date-range-picker-system-log {
    display: flex;
    flex-direction: column;

    .date-holder {
      display: flex;
      flex-direction: row;
      margin-right: 1.25rem !important;

      .date-picker-btn {
        .btn-icon {
          margin-right: 0.5rem;
        }

        border: 0.031rem solid #c3d3e6 !important;
        border-radius: 0.5rem !important;
        margin: 0;
        font-size: $Body-Small !important;
        font-family: inherit !important;
        color: black !important;

        span {
          margin: 0 0.625rem !important;
        }
      }

      .button {
        padding: 0.313rem !important;
      }

      .button:hover {
        background-color: $amber !important;
        color: $white !important;
      }
    }
  }

  .outcomes-add {
    font-size: $Body-Default;
    margin-top: 0.5rem;
  }

  .button-outcome {
    margin-right: $spacing-lg;
  }

  .submit-button {
    cursor: pointer;
    height: 2.857rem;
    background-color: $gradientRight;
    @include button-style;
    border: none;
    border-radius: 0.313rem;
    @include font-style(1rem, $white, normal);
    @include set-padding(0.563rem, 2.563rem, 0.563rem, 2.563rem);

    &:focus {
      outline: none !important;
    }
  }

  .submit-button-custom {
    cursor: pointer;
    height: 2.857rem;
    background-color: $gradientRight;
    @include button-style;
    border: none;
    border-radius: 0.313rem;
    @include font-style(1rem, $white, bold);
    @include set-padding(0.563rem, 2.563rem, 0.563rem, 2.563rem);
  }
}

.outcome-admin-padded {
  display: flex;
  flex-direction: row;
  padding: 1.25rem 1.25rem 1.25rem 0rem;
  background-color: white;
  justify-content: flex-end;

  .submit-button {
    cursor: pointer;
    height: 2.857rem;
    background-color: $gradientRight;
    @include button-style;
    border: none;
    border-radius: 0.313rem;
    @include font-style(1rem, $white, normal);
    @include set-padding(0.563rem, 2.563rem, 0.563rem, 2.563rem);

    &:focus {
      outline: none !important;
    }
  }

  .submit-button-custom {
    cursor: pointer;
    height: 2.857rem;
    background-color: $gradientRight;
    @include button-style;
    border: none;
    border-radius: 0.313rem;
    @include font-style(1rem, $white, bold);
    @include set-padding(0.563rem, 2.563rem, 0.563rem, 2.563rem);
  }
}

.outcome-admin-padded-help {
  display: flex;
  flex-direction: row;
  padding: 2.857rem 2.857rem 2.857rem 0rem;
  background-color: white !important;
  justify-content: flex-end;

  .submit-button {
    cursor: pointer;
    height: 2.857rem;
    background-color: $gradientRight;
    @include button-style;
    border: none;
    border-radius: 0.313rem;
    @include font-style(1rem, $white, bold);
    @include set-padding(0.563rem, 2.563rem, 0.563rem, 2.563rem);

    &:focus {
      outline: none !important;
    }
  }

  .submit-button-custom {
    cursor: pointer;
    height: 2.857rem;
    background-color: $gradientRight;
    @include button-style;
    border: none;
    border-radius: 0.313rem;
    @include font-style(1rem, $white, normal);
    @include set-padding(0.563rem, 2.563rem, 0.563rem, 2.563rem);
  }
}

.outcome-admin-custom {
  display: flex;
  flex-direction: row;
  background-color: transparent !important;
  justify-content: flex-end;

  .outcomes-add {
    font-size: $Body-Default;
    margin-top: 0.5rem;
  }

  .submit-button {
    cursor: pointer;
    height: 2.857rem;
    background-color: $gradientRight;
    @include button-style;
    border: none;
    border-radius: 0.313rem;
    font-size: $Body-Default !important;
    color: white !important;
    font-family: inherit !important;
    font-weight: 100 !important;
    @include set-padding(0.563rem, 2.563rem, 0.563rem, 2.563rem);
  }

  .submit-button-custom {
    cursor: pointer;
    height: 2.857rem;
    background-color: $gradientRight;
    @include button-style;
    border: none;
    border-radius: 0.313rem;
    @include font-style(1rem, $white, bold);
    @include set-padding(0.563rem, 2.563rem, 0.563rem, 2.563rem);
  }
}

.contentOrganization {
  background: white !important;
  padding: 0rem !important;

  .activity-model-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    .activity-model-buttons {
      flex-direction: column;

      .activity-confirm-button {
        margin-top: 1.25rem;
      }
    }
  }

  .act-inputs {
    height: 3.125rem !important;
  }

  .act-inputs-textarea {
    min-height: 10em !important;
  }

  small {
    margin-bottom: 2.857rem !important;
  }

  label {
    @include Body-Default;
  }

  .ui.grid {
    margin: 0rem !important;
  }

  .column,
  .row {
    padding: 0.438rem !important;
  }
}

.contentOrganization-edit {
  background: white !important;
  padding: 0rem !important;

  .activity-model-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    .activity-model-buttons {
      flex-direction: column;

      .activity-confirm-button {
        margin-top: 1.25rem;
      }
    }
  }

  .act-inputs {
    height: 3.125rem !important;
  }

  .act-inputs-textarea {
    min-height: 10em !important;
  }

  small {
    margin-bottom: 2.857rem !important;
  }

  label {
    @include Body-Default;
  }

  .ui.grid {
    margin: 0rem !important;
  }
}

.add-help-page {
  display: flex;
  flex-direction: row;
}

.super-admin-content-segment {
  padding: 0 !important;
  padding-bottom: 0.625rem !important;

  .system-log-table {
    overflow: auto !important;
  }

  .ui.segment {
    padding: 0 !important;
  }

  .org-users-text {
    @include Body-Default($Text-Secondary);
  }
}

.eval-qs-admin {
  height: fit-content;
  background-color: #ffffff !important;

  .eval-qs-select {
    padding-bottom: 1.875rem !important;

    .ui.segment {
      padding: 0 !important;
    }
  }

  .add-eval {
    display: flex;
    flex-direction: row;
    margin-top: 1.25rem;
    padding: 0.813rem 0rem 2.857rem 2.857rem;
    justify-content: space-between;
  }

  .button-outcome {
    margin-right: $spacing-lg;
  }

  .submit-button {
    height: 2.857rem;
    background-color: $gradientRight;
    @include button-style;
    border: none;
    border-radius: 0.313rem;
    @include font-style(1rem, $white, bold);
    @include set-padding(0.563rem, 2.563rem, 0.563rem, 2.563rem);
  }
}

.custom-tabs {
  width: inherit;

  .ui.segment {
    box-shadow: none;
    border-radius: none;
    border: none;
  }

  .ui.menu.borderless {
    border: none;
    box-shadow: none;

    .item {
      flex: 1 !important;
      display: flex !important;
      flex-wrap: wrap !important;
      justify-content: center !important;
      @include Body-Default-Semibold($Gray-90);
      background-color: $orgBackground !important;
      border-right: 0.063rem solid $background !important;
      text-align: center !important;
    }

    .item.active {
      background-color: $white !important;
      color: $gradientRight !important;
    }
  }
}

.custom-tabs-admin {
  width: 100%;

  .ui.segment {
    box-shadow: none;
    border-radius: none;
    border: none;
  }

  .ui.menu.borderless {
    border: none;
    box-shadow: none;

    .item {
      flex: 1 !important;
      display: flex !important;
      flex-wrap: wrap !important;
      justify-content: center !important;
      @include Body-Default-Semibold($info);
      background-color: $orgBackground !important;
      border-right: 0.063rem solid $background !important;
      text-align: center !important;
    }

    .item.active {
      background-color: $white !important;
      color: $gradientRight !important;
    }
  }
}

.two-step-radio-container-admin {
  margin-left: 0.813rem;

  .two-step-radio-container-qs {
    @media only screen and (max-width: 767px) {
      width: 90%;
      justify-content: flex-start;
      align-items: flex-start;

      label {
        flex: 6 !important;
        margin-right: 0.938rem;
      }
    }

    label {
      @include font-style(1rem !important, #ef8123);
      flex: 2;
      margin-right: 0.938rem;
    }

    margin: 0.313rem 0rem;

    .ui.fitted.toggle.checkbox {
      flex: 1/3;
    }

    .ui.toggle.checkbox {
      label {
        color: #ef8123;
        font-family: $fontFamily;
        font-size: $Body-Default;
      }
    }

    .ui.toggle.checkbox input:focus:checked ~ .box:before,
    .ui.toggle.checkbox input:focus:checked ~ label:before {
      background-color: #ef8123 !important;
    }

    .ui.toggle.checkbox input:focus:checked ~ .box:before,
    .ui.toggle.checkbox input:focus:checked ~ label:before {
      background-color: #ef8123 !important;
    }

    .ui.toggle.checkbox input:focus:checked ~ .box:before,
    .ui.toggle.checkbox input:focus:checked ~ label:before {
      background-color: #ef8123 !important;
    }

    .ui.toggle.checkbox input:focus:checked ~ .box:before,
    .ui.toggle.checkbox input:focus:checked ~ label:before {
      background-color: #ef8123 !important;
    }

    .ui.toggle.checkbox input:checked ~ .box:before,
    .ui.toggle.checkbox input:checked ~ label:before {
      background-color: #ef8123 !important;
    }

    .ui.toggle.checkbox input:checked ~ .box:before,
    .ui.toggle.checkbox input:checked ~ label:before {
      background-color: #ef8123 !important;
    }

    .ui.toggle.checkbox input:checked ~ .box:before,
    .ui.toggle.checkbox input:checked ~ label:before {
      background-color: #ef8123 !important;
    }
  }
}

.two-step-radio-container-admin-custom-top {
  margin-left: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 767px) {
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  label {
    margin-right: 0.938rem;
  }

  .permission-grant {
    margin-top: 0.313rem;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }
}

.two-step-radio-container-admin-custom-top-remove-btn {
  margin-left: 0rem;
  margin-top: 0rem;
  margin-bottom: 0rem;

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 767px) {
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  label {
    margin-right: 0.938rem;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }
}

.toggle-left-custom-feature {
  margin-left: 0.313rem;
  margin-top: 0.938rem;
  vertical-align: middle;
}

.two-step-radio-container-admin-system-log {
  margin-left: 0.813rem;

  @media only screen and (max-width: 767px) {
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;

    label {
      flex: 6 !important;
      margin-right: 0.938rem;
    }
  }

  .ui.fitted.toggle.checkbox {
    flex: 1/3;
  }

  .ui.toggle.checkbox {
    label {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
      margin-top: 0.625rem;
    }
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }
}

.two-step-radio-container-admin-system-log-custom-feature {
  margin-left: 0.813rem;

  @media only screen and (max-width: 767px) {
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;

    label {
      flex: 6 !important;
      margin-right: 0.938rem;
    }
  }

  .ui.fitted.toggle.checkbox {
    flex: 1/3;
  }

  .ui.toggle.checkbox {
    label {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
      margin-top: 0.625rem;
    }
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }
}

.modal-toggle-qs {
  margin-top: -0.25em;
}

.modal-toggle-qs-participant {
  margin-top: -0.25em;
  margin-left: -0.5em;
  margin-right: 0.5em;
}

.modal-toggle-qs-acctype {
  margin-top: -0.25em;
  margin-left: -1em;
  margin-right: 0.5em;
}

.custom-tabs-mobile {
  width: inherit;

  .ui.borderless.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .item {
    width: -webkit-fill-available;
    display: flex !important;
    justify-content: center !important;
    color: $info !important;
    font-family: $fontFamily !important;
    font-size: $Body-Small !important;
    font-weight: 600 !important;
    line-height: 1.188rem !important;
    background-color: $background !important;
  }

  .item.active {
    background-color: $white !important;
    color: $gradientRight !important;
  }
}

.ui.tab.active.custom-tab {
  padding: 0 !important;
  background-color: $background !important;
}

.gap-between-windows {
  .custom-segment {
    border: 0.063rem solid #ef8123 !important;
    border-radius: 0.25rem !important;
    border-top: 0.063rem solid #ef8123 !important;

    .ui.basic.table {
      background-color: white !important;
    }
  }

  .custom-segment:hover {
    background: rgba(239, 129, 35, 0.1) !important;
  }
}

.view-accordion-com {
  background-color: white !important;
}

.custom-error-label {
  width: 100%;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #dc3545 !important;
}

.custom-error-input .prompt {
  border-color: #dc3545 !important;
}

.custom-error-ck {
  border-color: #dc3545 !important;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  @include Body-Small($Error);
}

.no-ques {
  text-align: center;
  margin: 2.857rem auto;
}

.view-member-column {
  padding: 0 !important;
  border-bottom: 0.063rem solid rgba(34, 36, 38, 0.15) !important;

  .view-member-seg {
    max-height: 50vh;
    overflow-y: auto;

    .scroll-activity {
      padding-right: 1.25rem;
      max-height: 100%;
      overflow-y: auto;
    }

    .title-padding {
      padding-bottom: 1.25rem;
      margin-bottom: 0rem;
    }

    background-color: $white;
    @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);

    .member-holder {
      @include set-padding(1.25rem, 0, 1.25rem, 0);
      border-bottom: 0.063rem solid #eeeeee;

      .member-activity-role {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .member-activity {
          @include font-style(1rem, $info, 600);
          padding-left: 1.25rem;
        }

        .member-role {
          @include font-style(0.75rem, $info, 600);
          opacity: 0.6;
          text-transform: capitalize;
        }
      }

      .member-email {
        @include font-style(0.75rem, $info);
        opacity: 0.6;
      }
    }
  }
}

.view-member-column,
.add-member-column {
  .form-title {
    @include font-style(1rem, $info, 600);
    opacity: 0.8;
  }
}

.view-accordion-com {
  padding: 0 !important;
  border-bottom: 0.063rem solid rgba(34, 36, 38, 0.15) !important;

  .view-accordion-seg {
    .accordion-activity {
      padding-right: 1.25rem;
      max-height: 100%;
    }

    .title-padding {
      padding-bottom: 1.25rem;
      margin-bottom: 0rem;
    }

    background-color: $white;
    @include set-padding(1.25rem, 1.875rem, 0.938rem, 1.875rem);

    .help-tip-container {
      .help-tip-info {
        display: grid;
        border-bottom: 0.063rem solid #f3f3f3;
        grid-template-columns: repeat(5, 1fr);
        padding: 0.625rem 0.313rem;
      }

      .help-tip-content {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }

      .gap-icon {
        margin-right: 0.313rem;
      }

      .help-tip-actions {
        background-color: #685f58;
        padding: 0 !important;
        text-align: right !important;
      }
    }

    .help-tip-container:hover {
      background-color: #f3f3f3 !important;
    }

    .member-holder {
      @include set-padding(1.25rem, 0, 1.25rem, 0);
      border-bottom: 0.063rem solid #eeeeee;

      .member-activity-role {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .member-activity {
          @include font-style(1rem, $info, 600);
          padding-left: 1.25rem;
        }

        .member-activity-inactive {
          @include font-style(1rem, $info, 600);
          padding-left: 1.25rem;
          opacity: 0.5;
        }

        .member-role {
          @include font-style(0.75rem, $info, 600);
          opacity: 0.6;
          text-transform: capitalize;
        }
      }

      .member-email {
        @include font-style(0.75rem, $info);
        opacity: 0.6;
      }
    }
  }
}

.view-help-tips {
  padding: 0 !important;
  border-bottom: 0.063rem solid rgba(34, 36, 38, 0.15) !important;

  .view-help-tips {
    .scroll-help-tips {
      padding-right: 1.25rem;
    }

    .title-padding {
      padding-bottom: 1.25rem;
      margin-bottom: 0rem;
    }

    background-color: $white;
    @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);

    .member-holder {
      @include set-padding(1.25rem, 0, 1.25rem, 0);
      border-bottom: 0.063rem solid #eeeeee;

      .member-activity-role {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .member-activity {
          @include font-style(1rem, $info, 600);
          padding-left: 1.25rem;
        }

        .member-role {
          @include font-style(0.75rem, $info, 600);
          opacity: 0.6;
          text-transform: capitalize;
        }
      }

      .member-email {
        @include font-style(0.75rem, $info);
        opacity: 0.6;
      }
    }
  }
}

.scroll-accordion {
  td {
    padding-bottom: 1rem !important;
  }
}

.view-member-eval-qs {
  padding: 0 !important;

  .view-member-seg {
    max-height: 100%;

    .scroll-activity {
      padding-right: 1.25rem;
      max-height: 100%;
    }

    .title-padding {
      padding-bottom: 1.25rem;
      margin-bottom: 0rem;
    }

    background-color: $white;
    @include set-padding(2.857rem, $spacing-xl, 2.857rem, $spacing-xl);

    .member-holder {
      @include set-padding(1.25rem, 0, 1.25rem, 0);
      border-bottom: 0.063rem solid #eeeeee;

      .member-activity-role {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .member-activity {
          @include font-style(1rem, $info, 600);
          padding-left: 1.25rem;
        }

        .member-role {
          @include font-style(0.75rem, $info, 600);
          opacity: 0.6;
          text-transform: capitalize;
        }
      }

      .member-email {
        @include font-style(0.75rem, $info);
        opacity: 0.6;
      }
    }
  }
}

.view-member-eval-qs,
.add-member-eval-qs {
  .form-title {
    @include font-style(1rem, $info, 600);
    opacity: 0.8;
  }
}

.admin-actions-warn {
  color: $gradientRight !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 600;
  line-height: 1.375rem !important;
  text-align: right;
  cursor: pointer;
}

.view-features {
  color: green !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 600;
  line-height: 1.375rem !important;
  text-align: right;
  cursor: pointer;
}

.admin-actions-error {
  color: $red !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 600;
  line-height: 1.375rem !important;
  text-align: right;
  cursor: pointer;
}

.admin-actions-toggle {
  color: $gradientRight !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 600;
  line-height: 1.375rem !important;
  text-align: right;
  cursor: pointer;
}

.admin-actions-toggle-advance {
  color: $gradientRight !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 600;
  line-height: 1.375rem !important;
  text-align: center;
  cursor: pointer;
}

.no-color {
  background-color: #ffffff !important;
  padding: $spacing-lg $spacing-xl;
}

.admin-actions-success {
  color: $success !important;
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  font-weight: 600;
  line-height: 1.375rem !important;
  text-align: right;
  cursor: pointer;
}

.gap-between-ck {
  margin-top: $spacing-lg;
}

.gap-between-edit {
  margin-top: 29px;
}

.gap-between-collapse {
  margin-top: 1rem;
}

.gap-activity {
  margin-top: 1.563rem;
}

.checkbox-admin {
  font-weight: bold;
}

.first-down {
  margin-left: 2.857rem;
}

.first-right {
  margin-right: 1.25rem;
}

.first-up {
  margin-top: -2.857rem;
}

.output-format-modal {
  display: flex;
  flex-direction: row;
  margin-top: 0.625rem;
  margin-right: 6.25rem;

  .output-format-left {
    margin-left: 1.875rem;
  }

  .list-icon {
    padding: 2.313rem 0.625rem 0rem 0rem;
  }
}

.label-remove {
  cursor: pointer;
  background-color: transparent;
  color: #d6001a;
  font-family: $fontFamily;
  font-size: $Body-Small;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
  padding: 2.313rem 0rem 1.875rem 0.938rem;
}

.label-remove-of {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
}

.output-format-modal-custom-feature {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  margin-top: 0.625rem;

  .output-format-left {
    margin-left: 1.875rem;
  }

  .list-icon {
    margin: auto;
  }
}

@media only screen and (max-width: 767px) {
  .output-format-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .outcome-admin {
    display: flex;
    flex-direction: column;
  }
}

.add-field {
  width: 16%;
  border-radius: 0.25rem;
  background-color: transparent;
  color: $gradientRight;
  font-family: $fontFamily;
  font-size: $Body-Small;
  font-weight: 600;
  line-height: 1.5rem;
  cursor: pointer;
}

.ui.button.goal-add {
  width: 185px;
  border-radius: 0.25rem;
  background-color: transparent;
  color: $gradientRight;
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-weight: 600;
  line-height: 1.5rem;
  margin-left: -1.563rem;
}

.ui.button.goal-add:hover,
.ui.buttons.goal-add .button:hover {
  background-color: transparent !important;
  color: $gradientRight !important;
}

.ui.button.goal-add:active {
  background-color: transparent !important;
}

.output-format-input {
  height: 3.125rem !important;
  width: 195px;
}

.output-format-input-custom-feature {
  height: 3.125rem !important;
  width: 12.5rem;
}

.featureLink {
  display: inline-block !important;
  cursor: pointer !important;
}

.output-format-input-custom-feature-admin {
  height: 3.125rem !important;
  width: 12.5rem !important;
  border-color: #ef8123;
  text-align: center;
}

input[type='userStory1']:disabled {
  background: #ffffff;
  color: #ef8123 !important;
}

.output-format-input-custom-feature-userstory {
  height: 3.125rem !important;
  width: 195px;
}

.org-inputs {
  height: 2.857rem !important;
  border-radius: 0.5rem !important;
}

.org-inputs-individual-phone {
  height: 2.857rem !important;
  border-radius: 0.5rem !important;
  margin-bottom: -10px;
}

.reassign-inputs {
  height: 2.857rem !important;
  border-radius: 0.5rem !important;
}

.eval-qs-select {
  width: 50%;
  padding: 0rem 3.125rem 2.857rem 2.857rem;
}

.version-table {
  margin-bottom: 1.875rem;

  .default-span {
    margin-left: 0.625rem;
    background-color: $gradientRight;
    border-radius: 0.5rem;
    width: 5.625rem;
    font-size: $Body-Small;
    color: white;
    font-weight: 600;
  }

  .secondary-span {
    margin-left: 0.625rem;
    background-color: gray;
    border-radius: 0.5rem;
    width: 5.625rem;
    font-size: $Body-Small;
    color: white;
  }
}

.feature-name {
  text-align: center;
  width: 5.625rem;
}

.feature-name-top {
  margin-left: -5rem;
}

.filter-system-log {
  background-color: white;
  display: flex;
  flex-direction: row;
  padding: 1.25rem 1.875rem 1.25rem 1.875rem;

  .span-tag {
    margin-right: 0.625rem;
    height: 2.857rem;
    background-color: lemonchiffon;
    border: none;
    font-size: $Body-Small;
    padding: 0.563rem 1.438rem 1.25rem 1.438rem;
    border-radius: 0.313rem;
    font-weight: 400;
    line-height: 1.25rem;

    .close-left {
      padding: 0.25rem 0rem 0.313rem 0.5rem;
      cursor: pointer;
    }
  }
}

.tag-filter {
  font-size: $Body-Small;
}

.system-top {
  margin-top: 1.563rem;
}

.helps-admin {
  height: fit-content;
  background-color: white;
}

.help-c {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0.313rem 38px;

  .help-add {
    font-size: $Body-Default;
    margin-top: 0.5rem;
  }

  .icon-adjust {
    margin-top: 0.625rem;
    margin-right: 26px;
  }
}

.gap-icon {
  margin-right: 1.875rem;
}

.dropdown-col {
  transform: rotate(90deg);
}

.title-width {
  width: 100% !important;
}

.accordion-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.accordion-edit {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .left-side {
    margin: auto;
  }

  .icon-left {
    margin: auto;
    font-size: $h6 !important;
    color: #ef8123 !important;
  }

  .accordion-right-title {
    cursor: pointer;
    padding: 0.438rem 0.188rem 0rem 0rem;
    color: #ef8123 !important;
    font-family: $fontFamily !important;
    font-size: $Body-Default !important;
    font-weight: 600;
  }
}

.view-collapse-eval-qs {
  padding: 0 !important;

  .view-collapse-seg {
    max-height: 110vh;
    overflow-y: auto;
    box-shadow:
      0 0.125rem 0.25rem 0 rgba(34, 36, 38, 0.12),
      0 0.125rem 0.625rem 0 rgba(34, 36, 38, 0.15);

    .scroll-collapse {
      padding-right: 1.25rem;
      max-height: 100%;
      overflow-y: auto;
    }

    .title-padding {
      padding-bottom: 1.25rem;
      margin-bottom: 0rem;
    }

    background-color: $white;
    @include set-padding(0rem 0.125rem 26px 0rem);
    margin: 2.563rem;
    margin-top: 0.063rem;

    .member-holder {
      @include set-padding(0.688rem 0 0rem 1.063rem);
      border-bottom: 0.063rem solid #eeeeee;

      .member-collapse-role {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .member-collapse {
          @include font-style(1rem, $info, 600);
          padding-left: 3.75rem;
          margin-top: 1.063rem;
        }

        .member-collapse-role {
          @include font-style(0.75rem, $info, 600);
          opacity: 0.6;
          text-transform: capitalize;
        }
      }

      .member-email {
        @include font-style(0.75rem, $info);
        opacity: 0.6;
      }
    }
  }
}

.view-collapse-eval-qs,
.add-collapse-eval-qs {
  .form-title {
    @include font-style(1rem, $info, 600);
    opacity: 0.8;
  }
}

.organization-table-red {
  color: $red;
}

.organization-table-green {
  color: green;
}

.action-flex {
  display: flex;
  flex-direction: row;
  color: $gradientRight;

  .action-org {
    color: $gradientRight;
    font-size: $Body-Default;
    font-weight: 600;
  }

  .icon-down {
    margin-left: 0.188rem;
    margin-top: 0.063rem;
  }
}

.org-users-actions-admin {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: $gradientRight;
}

.remove-user {
  color: $red;
}

.ui.grid > .act-reassign-row {
  background-color: white;
  border-radius: 0.313rem;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .add-reassign-column {
    padding: 0 !important;

    .reassign-segment {
      @include set-padding(1.25rem);
      margin-bottom: 0;

      .participants-title {
        @include font-style;
        opacity: 0.6;
      }

      display: flex;
      flex-direction: column;

      .search-label {
        padding-top: 1.25rem;
      }
    }

    .text-reassign {
      font-size: $Body-Default;
    }
  }
}

.dropdown-search-padding {
  padding: 0rem 127px 0rem 0rem;
}

.ui.grid > .org-row {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .org-modal-column {
    padding-top: 1rem;
    padding-bottom: 1rem;

    .form-group {
      margin-top: 1.25rem;
    }

    .org-inputs {
      height: 2.857rem !important;
    }

    label {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Small;
      line-height: 1.063rem;
    }

    select {
      width: 70% !important;
    }
  }
}

.down-modal-individual-right-side {
  padding: 41px 1.875rem 20px 1.063rem;
}

.down-modal-admin {
  padding: $spacing-lg 1.875rem 0rem 1.063rem;
}

.org-checkbox {
  .form-check {
    background-color: white !important;
  }
}

.org-checkbox-edit {
  .form-check {
    background-color: white !important;
    margin-left: -1.25rem;
    margin-top: $spacing-lg;
    display: flex !important;
  }
}

.org-checkbox-diff {
  .form-check {
    display: flex !important;
    align-items: center !important;
    background-color: white !important;
  }
}

.add-org-admin {
  display: flex;
  flex-direction: column;

  .org-header {
    @include Body-Default-Semibold;
    margin-left: 15px;
  }
}

.reassign-org-admin {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.375rem;

  .reassign-header {
    @include Body-Default-Semibold($info);
  }

  .reassign-description {
    margin-top: 0.313rem;
    color: $info;
    font-family: $fontFamily !important;
    font-size: $Body-Small !important;
    line-height: 1.063rem;
  }
}

.org-admin-modal {
  padding: 0.625rem 0.438rem 0rem 2.857rem;
}

.segment-org-Modal {
  padding: 0rem 0.375rem 0rem 0rem;
  box-shadow: 0rem 0.125rem 0.375rem rgba(210, 210, 210, 0.5);
}

.model-content-org {
  padding: 0.938rem 0.188rem 0rem 0.313rem !important;
}

.org-title-advance {
  font-family: $fontFamily;
  line-height: 1.375rem;
  padding: $spacing-sm 0rem $spacing-sm 1.125rem;
  font-size: $Body-Default;
  color: #ef8123;
  font-weight: 600;
  font-style: normal;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  width: 20%;
}

.organization-details {
  display: flex;
  flex-direction: row;
  background-color: $background;
  height: 3.125rem;
  justify-content: space-between;
  border-radius: 0.28571429rem;
  margin-right: 0.313rem;

  .org-titleo {
    font-family: $fontFamily;
    font-size: $Body-Default;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
    padding: $spacing-sm 0rem $spacing-sm 1.125rem;
  }

  .icon-modal {
    padding: $spacing-sm 1.125rem $spacing-sm 0rem;
  }
}

.version-det {
  padding: 1.25rem 0.563rem 0.063rem 0.563rem;

  .version-title {
    font-size: $Body-Default;
    font-weight: 600;
    padding-left: 1.25rem;
  }

  .feature-select {
    width: 12.5rem;
    height: 43px;
  }
}

.view-feature-qs {
  padding: 0 !important;

  .view-feature-sg {
    max-height: 110vh;
    overflow-y: auto;

    .scroll-activity {
      padding-right: 1.25rem;
      max-height: 100%;
      overflow-y: auto;
    }

    .title-padding {
      padding-bottom: 1.25rem;
      margin-bottom: 0rem;
    }

    .feature-holder {
      @include set-padding(1.25rem, 0, 0.375rem, 0);

      .member-activity-role {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .feature-activity {
          @include font-style(1rem, $info, 200);
          padding-left: 1.25rem;
          margin-top: 0.313rem;
        }

        .feature-action-area {
          display: flex;
          flex-direction: row;
        }

        .member-role {
          @include font-style(0.75rem, $info, 600);
          opacity: 0.6;
          text-transform: capitalize;
        }

        .toggle-left {
          margin-right: 65px;
          margin-top: 0.438rem;
        }
      }

      .member-email {
        @include font-style(0.75rem, $info);
        opacity: 0.6;
      }
    }
  }
}

.advance-feature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.feature-action-area {
  display: flex;
  flex-direction: column;

  .feature-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    i {
      cursor: pointer;
    }
  }
}

.date-holder-system {
  margin-bottom: 0.75rem;
  margin-left: 0.313rem;
  color: $info;
  background-color: transparent;
  font-family: $fontFamily;
  font-size: $Body-Small;
  line-height: 1.188rem;
}

.ul-col {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.tag-flex {
  display: flex;
  flex-direction: row;

  .span-tag {
    margin-right: 0.625rem;
    height: 2.857rem;
    background-color: thistle;
    border: none;
    font-size: $Body-Small;
    padding: 0.563rem 1.438rem 1.25rem 1.438rem;
    border-radius: 0.313rem;

    .close-left {
      padding: 0.25rem 0rem 0.313rem 0.5rem;
      cursor: pointer;
    }
  }
}

.height-admin {
  height: 72vh;
}

.table-ven th,
.table-ven tr {
  border-top: none !important;
  border-bottom: 0.063rem solid rgba(0, 0, 0, 0.1) !important;
}

.venue-name {
  font-size: $Body-Default;
}

.ui.button.user-story {
  height: 3.125rem !important;
  width: 250px;
  margin-top: 1.563rem;
  border-radius: 0.25rem;
  background-color: transparent;
  box-shadow:
    0 0 0 0.063rem #ef8123 inset,
    0 0 0 0 transparent;
  color: #ef8123;
  font-family: $fontFamily;
  font-size: $Body-Large;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
}

.edit-version-details-status-bar {
  display: flex;
  flex-direction: column;
  margin-left: 0.625rem;

  .version-details-toggle-left {
    margin-left: 0.625rem;
    margin-top: 0.938rem;
  }

  .version-details-toggle-left-system-logs {
    position: absolute;
    margin-left: 0.625rem;
    margin-top: 2.813rem;
  }
}

.feature-details {
  margin-bottom: $spacing-lg;
  height: 1rem;
  left: 3.37%;
  right: 85.88%;
  top: calc(50% - 1rem / 2 - 323.313rem);

  @include Body-Default-Semibold(#3b3b3b);
}

.user-activity-custom th,
.user-activity-custom td {
  border-top: none !important;
  border-bottom: 0.063rem solid rgba(0, 0, 0, 0.1) !important;
  color: $info;
  font-family: $fontFamily;
  font-size: $Body-Default;
  line-height: 1.375rem;
}

.user-activity-custom thead {
  th {
    border-bottom: none !important;
  }
}

.status-custom-feature {
  font-size: $Body-Small;
}

.user-act-heading-container-custom-feature {
  display: flex;
  flex-direction: column;
  text-align: left;

  .user-act-heading-type {
    color: #898989;
    font-family: $fontFamily;
    font-size: $Body-Small;
    line-height: 1.188rem;
    text-align: center;
  }
}

.user-act-heading-container-custom-feature-description {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 12.5rem;

  .user-act-heading-type {
    color: #898989;
    font-family: $fontFamily;
    font-size: $Body-Small;
    line-height: 1.188rem;
    text-align: center;
  }
}

.empty-margin-custom-feature {
  padding-top: 1.25rem;
}

.edit-user {
  cursor: pointer;
}

.edit-org {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
  justify-content: space-between !important;
  font-weight: normal !important;
  cursor: pointer !important;

  i {
    margin-left: 0.5rem !important;
  }
}

.edit-org-edit {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-end !important;
  color: $amber !important;
  justify-content: space-between !important;
  font-weight: normal !important;
  color: $gradientRight !important;
  cursor: pointer !important;
  margin-left: 8.5rem !important;
  margin-right: -1.5rem !important;

  i {
    margin-left: 0.5rem !important;
  }
}

.org-radio {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.checkbox-background {
  background-color: white;
}

.block-status {
  color: $red;
}

.subscription-status {
  color: $red;
}

.subscription-status.pending {
  color: $gradientRight;
}

.subscription-status.paid {
  color: $green;
}

.user-remove {
  color: $red;
  text-align: right;
  cursor: pointer;
}

.user-type-change {
  @include font-style(1rem, $gradientRight, 600);
  background-color: transparent;
  cursor: pointer;
  margin-right: 2.857rem;
}

.user-heading-container {
  display: flex;
  flex-direction: column;
  text-align: left;

  .user-heading-type {
    color: #898989;
    font-family: $fontFamily;
    font-size: $Body-Small;
    line-height: 1.188rem;
    text-align: center;
  }
}

.model-buttons-org {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.model-buttons-account {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1.875rem 0.375rem 1.25rem 1.875rem;
}

.mark-paid-modal {
  padding: 0.313rem 0.625rem 0rem 0.625rem;
}

.ques-icon {
  margin: 0.625rem 0rem 0rem 0.625rem;
}

.ques-icon-bg {
  display: block;
  cursor: pointer;
  background-color: $gradientRight;
  color: white;
}

.org-users {
  // .add-venue-org-row {
  //   margin-top: 1rem !important;
  // }

  .org-users-table {
    th,
    td {
      vertical-align: bottom !important;
    }

    .org-users-container {
      display: flex;
      flex-direction: column;
    }

    .org-users-default-container {
      display: flex;
      flex-direction: column;
    }

    .org-users-cdn-container {
      display: flex;
      flex-direction: row;
    }

    @media only screen and (max-width: 1624px) {
      .cdn-admin {
        height: fit-content !important;
      }
    }

    .cdn-admin {
      margin-left: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .org-users-mainText {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
      line-height: 1.375rem;
      font-weight: 300;
    }

    .org-users-text-container {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .org-users-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .org-users-actions-warn {
      color: $gradientRight !important;
      font-family: $fontFamily;
      font-size: $Body-Default;
      font-weight: 400;
      line-height: 1.375rem;
      text-align: right;
      cursor: pointer;
    }

    .org-users-actions-error {
      color: $red;
      font-family: $fontFamily;
      font-size: $Body-Default;
      font-weight: 400;
      line-height: 1.375rem;
      text-align: right;
      cursor: pointer;
    }

    .org-users-actions-success {
      color: $success;
      font-family: $fontFamily;
      font-size: $Body-Default;
      font-weight: 400;
      line-height: 1.375rem;
      text-align: right;
      cursor: pointer;
    }
  }

  .add-user {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media only screen and (max-width: 767px) {
    .add-user {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .org-users-actions {
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
    }

    .first-down {
      margin-top: 0.625rem !important;
    }
  }

  .activitySearch-user {
    .ui.icon.input {
      width: 100% !important;

      input {
        border-radius: 0.25rem;
        border: 0.063rem solid #ced4da;
      }
    }
  }

  .guest-search {
    @media (max-width: 48rem) {
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    display: inline-flex;

    .guest-search-container {
      margin-right: $spacing-lg;

      .search-label {
        @include font-style(0.75rem, $info);
      }

      .activitySearch {
        .ui.icon.input {
          width: 100% !important;

          input {
            border-radius: 0.25rem;
            border: 0.063rem solid #ced4da;
          }
        }

        .results.transition.visible {
          display: none !important;
        }
      }
    }
  }

  .ui.label {
    margin-top: 0.188rem;
  }
}

.user-search-admin {
  display: flex;
  flex-direction: row;

  .search-bar {
    margin-right: 1.25rem;
  }

  .search-bar-user {
    margin-right: 1.25rem;
    display: flex;
    flex-direction: column;
  }

  .account-filter-title {
    font-size: $Body-Small;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0rem;
    text-align: left;
    width: 103px;
  }

  .status-filter-title {
    font-size: $Body-Small;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0rem;
    text-align: left;
    width: 103px;
  }

  .org-search {
    margin-right: 0.625rem;
    width: 16.375rem;
  }

  .search-label {
    font-size: $Body-Small;
    color: #3b3b3b;
    font-weight: 100;
    font-style: normal;
  }

  .results.transition.visible {
    display: none !important;
  }

  .ui.icon.input {
    width: 16.375rem;
    height: 2.563rem;

    input {
      border-radius: 0.25rem;
      border: 0.063rem solid #e9e9e9;
      box-sizing: border-box;
    }
  }
}

.dropdown-org-user {
  height: 2.857rem !important;
  border-radius: 0.5rem !important;
}

.add-org-header {
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-style: normal;
  font-weight: 600;
  line-height: 1.375rem;
  letter-spacing: 0rem;
  text-align: left;
}

.date-range-picker-system-log {
  display: flex;
  flex-direction: column;

  .date-holder {
    display: flex;
    flex-direction: row;

    .date-picker-btn {
      .btn-icon {
        margin-right: 0.5rem;
      }

      background-color: transparent;
      padding: 0 !important;
      margin: 0;
      @include font-style(0.75rem, $info);
      opacity: 0.7;
    }

    .button {
      padding: 0.313rem !important;
    }

    .button:hover {
      background-color: $amber !important;
      color: $white !important;
    }
  }
}

.ui.button.date-picker-btn {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  height: 2.857rem;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.6);
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: normal;
}

.venue-name-disabled {
  color: gray;
  padding-bottom: 1.25rem;
}

.evaluation-div-loader-admin {
  display: flex !important;
  width: 100% !important;
  margin-bottom: 1.563rem !important;
  color: gray;
}

.question-edit-qs {
  text-align: right;
}

.enable-question {
  padding: 1.25rem 0rem 0rem 0rem;
}

.ques-new {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .ques-edit {
    margin-right: 3.75rem;
  }

  .ques-disable {
    margin-right: 1.563rem;
  }
}

@media only screen and (max-width: 767px) {
  .ques-new {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.venue-name-disabled {
  color: gray;
}

.evaluation-div-loader-admin {
  display: flex !important;
  width: 100% !important;
  margin-bottom: 1.563rem !important;
  color: gray;
}

.ui.button.output-format-remove {
  width: 10.188rem;
  border-radius: 0.25rem;
  background-color: transparent;
  color: $red;
  font-family: $fontFamily;
  font-size: $Body-Large;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
}

.question-edit-qs {
  text-align: right;
}

.ques-new {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .ques-edit {
    margin-right: 3.75rem;
  }

  .ques-disable {
    margin-right: 1.563rem;
  }
}

@media only screen and (max-width: 767px) {
  .ques-new {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.version-details-admin {
  margin-top: 0.938rem;
}

.goal-cancel-button-feature-update {
  width: 10.188rem;
  border-radius: 0.25rem;
  background-color: transparent;
  color: $red;

  font-family: $fontFamily;
  font-size: $Body-Large;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
}

.goal-cancel-button-custom {
  width: 10.188rem;
  border-radius: 0.25rem;
  background-color: transparent !important;
  color: transparent !important;

  font-family: $fontFamily;
  font-size: $Body-Large;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: center;
}

.org-inputs-textarea {
  border-radius: 0.5rem !important;
  min-height: 10em !important;
}

.venue-form {
  .form-check {
    padding: 0;
    margin-top: 0.625rem;
  }

  .venue-type-des {
    margin-bottom: 1rem;
    font-style: italic;
  }
}

.anchor-tag-admin {
  .radio-left {
    margin-top: 0.125rem;
    margin-left: 0.5rem;
  }
}

.two-step-radio-container-admin {
  @media only screen and (max-width: 767px) {
    width: 90%;
    justify-content: flex-start;
    align-items: flex-start;

    label {
      flex: 6 !important;
      margin-right: 0.938rem;
    }
  }

  label {
    @include font-style(1rem !important, $info);
    flex: 2;
    margin-right: 0.938rem;
  }

  .ui.fitted.toggle.checkbox {
    flex: 1/3;
  }

  .ui.toggle.checkbox {
    label {
      color: $info;
      font-family: $fontFamily;
      font-size: $Body-Default;
    }
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }

  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #ef8123 !important;
  }
}

.status-left {
  margin-left: 0.938rem;
}

.org-disabled {
  opacity: 0.3;
}

.advance-margin {
  margin-top: 0.375rem;
  margin-left: 1.25rem;
}

.ques-field {
  display: flex;
  flex-direction: row;
}

.empty-left {
  margin-left: 104px;
}

.system-log-date-range {
  width: 500px;
  display: flex;
  flex-direction: row;
  .date-range-container {
    width: 250px !important;
  }
}

.model-buttons-systemLog {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.75rem;
}

.save-system-log {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.model-contactor-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1.25rem;
}

.ui.button.system-reset-button {
  width: 5rem;
  border-radius: 0.25rem;
  background-color: $info;
  color: $white;
  font-family: $fontFamily;
  font-size: $Body-Small !important;
  font-weight: 400;
  line-height: 0.75rem;
  text-align: center;
  height: 2.25rem !important;
}

.sys-inputs {
  height: 3.125rem !important;
  padding: 1.063rem 0rem 1.063rem 0.813rem !important;
}

.status-right {
  margin-left: 0.5rem;
}

.help-add-accordion {
  cursor: pointer;
  padding: 0rem 1.75rem 0.188rem 0rem;
  color: #ef8123 !important;
  font-family: $fontFamily !important;
  font-size: $Body-Small !important;
  font-weight: 600;
}

.help-add-accordion:hover {
  color: #cc5f00 !important;
}

.activity-actions-warn:hover {
  color: #cc5f00 !important;
}

.features-warning {
  padding: 0.063rem 0.125rem 0.25rem 2.25rem;
}

.activetxt {
  color: green;
  font-family: $fontFamily !important;
  font-weight: 600;
}

.inactivetxt {
  color: gray;
  font-family: $fontFamily !important;
  font-weight: 600;
}

.acc-table-th {
  margin-left: 2.857rem;
}

.billing-filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center !important;

  .label {
    margin-right: 1rem !important;
  }

  .search-bar-user {
    display: flex;
    flex-direction: column;
  }

  .account-filter-title {
    font-size: $Body-Small;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0rem;
    text-align: left;
    margin-right: 53px;
  }

  .org-search {
    margin-right: 0.625rem;
    width: 16.375rem;
  }

  .search-label {
    font-size: $Body-Small;
    color: #3b3b3b;
    font-weight: 100;
    font-style: normal;
  }

  .results.transition.visible {
    display: none !important;
  }

  .ui.icon.input {
    width: 16.375rem;
    height: 2.563rem;

    input {
      border-radius: 0.25rem;
      border: 0.063rem solid #e9e9e9;
      box-sizing: border-box;
    }
  }
}

.acc-billing-table {
  margin-top: 3.125rem !important;
}

.admin-org-acc-type {
  margin-top: 1rem;
}

.acc-marked {
  display: flex;
  flex-direction: row;

  .icon-comment {
    margin-left: 0.625rem;
  }
}

.markPaid {
  color: $gradientRight;
  cursor: pointer;
}
.admin-org-user-upper {
  margin-left: 12px;
}
.org-ind-admin {
  .admin-org-title {
    font-size: $Body-Default;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
    letter-spacing: 0rem;
    text-align: left;
  }

  .admin-org-title-new {
    font-size: $Body-Default;
    font-style: normal;
    font-weight: 600;
    line-height: 1.375rem;
    letter-spacing: 0rem;
    text-align: left;
    margin-left: 10px;
  }

  .radio-org-add {
    margin-top: $spacing-lg;
    margin-bottom: $spacing-lg;
  }

  .radio-text {
    font-size: $Body-Default;
  }
}

.org-ind-subscripton {
  padding: 0rem 37px 0px 30px !important;
}

.subscription-title {
  font-size: $Body-Default;
}

.org-details-header {
  @include Body-Default-Semibold;
  padding-bottom: $spacing-sm !important;
  padding-top: $spacing-sm !important;
}

.org-details-raw {
  padding-bottom: $spacing-sm !important;
}

.org-radio {
  padding-left: 0 !important;

  legend {
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Small;
    line-height: 1.063rem;
  }

  .label {
    font-size: $Body-Default;
  }

  .form-control {
    background-color: transparent;
  }
}

.invoice-link {
  color: $info;
  text-decoration: underline;
}

.invoice-link:hover {
  color: $info;
  text-decoration: underline;
}

.admin-table thead th {
  border: none !important;
}

.admin-table thead {
  border: none !important;
  box-shadow: none;
  background: none !important;
}

.ui.grid > .permission-features-list {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-direction: row !important;
}

.invalid-permission-group-custom {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #dc3545;
}

.features-checkbox-container {
  justify-content: space-between;

  .features-checkbox {
    margin-left: 0.938rem;

    .form-check {
      background-color: #fff !important;
    }
  }
}

.feature-err {
  color: #dc3545 !important;
}

.featurelist-heder {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 1rem;
  margin-top: $spacing-lg;
}

.active-status-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .active-status {
    color: $green;
  }

  .circle {
    min-height: 0.625rem;
    height: 0.625rem;
    min-width: 0.625rem;
    width: 0.625rem;
    border-radius: 0.625rem;
    margin-right: 0.625rem;
    background-color: $info;
  }

  .activity-success {
    background-color: $green;
  }

  .activity-error {
    background-color: $red;
  }
}

.align-left {
  text-align: left !important;
}

.flex-group {
  display: flex;
  align-items: center;

  :first-child {
    margin-right: 0.625rem !important;
  }
}

.gap-between-windows {
  margin-top: $spacing-lg !important;

  .custom-segment {
    border: 0.063rem solid #ef8123 !important;
    border-radius: 0.25rem !important;
    border-top: 0.063rem solid #ef8123 !important;

    .ui.basic.table {
      background-color: white !important;
    }
  }

  .custom-segment:hover {
    background: rgba(239, 129, 35, 0.1) !important;
  }
}

.accordion-container {
  padding: 0 $spacing-xl !important;
  background-color: #ffffff !important;
}

.gap-between-windows-help {
  .ui.raised.segment,
  .ui.raised.segments {
    margin-bottom: $spacing-lg !important;
  }

  .custom-segment {
    border: 0.063rem solid #ef8123 !important;
    border-radius: 0.25rem !important;
    border-top: 0.063rem solid #ef8123 !important;

    .ui.basic.table {
      background-color: white !important;
    }
  }

  .custom-segment:hover {
    background: rgba(239, 129, 35, 0.1) !important;
  }
}

.help-list-container {
  background-color: white !important;
}

.event-details-segment {
  background-color: white !important;
}

.ui.secondary.pointing.menu {
  font-family: inherit !important;
}

.user-search-account-settings {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  width: 100% !important;

  .search-bar {
    margin-right: 1.25rem;
  }

  .search-bar-user {
    margin-right: 1.25rem;
    display: flex;
    flex-direction: column;
  }

  .account-filter-title {
    font-size: $Body-Small;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0rem;
    text-align: left;
    width: 103px;
  }

  .org-search {
    margin-right: 0.625rem;
    width: 16.375rem;
  }

  .search-label {
    font-size: $Body-Small;
    color: #3b3b3b;
    font-weight: 100;
    font-style: normal;
  }

  .results.transition.visible {
    display: none !important;
  }

  .ui.icon.input {
    min-width: 460px;
    height: 2.563rem;

    input {
      border-radius: 0.25rem;
      border: 0.063rem solid #e9e9e9;
      box-sizing: border-box;
    }
  }
}

.user-search-account-filter {
  display: flex;
  width: 100% !important;

  .account-filter {
    margin-left: 1rem !important;
  }

  .status-filter {
    margin-left: 1rem !important;
  }

  .account-filter-title {
    font-weight: 100 !important;
  }

  .status-filter-title {
    font-weight: 100 !important;
    margin-left: 3rem !important;
  }
}

.outcome-admin-less-padding {
  background-color: white !important;
  padding: $spacing-lg $spacing-xl !important;
}

.owned {
  .ui.dropdown {
    .item {
      padding: 0.75rem 1rem !important;
    }

    .edit-org {
      margin: 0 !important;
      width: 100% !important;
    }
  }
}

.content-segment-users {
  padding: 0 !important;
}

.outcome-admin-users {
  display: flex;
  flex-direction: row;
  padding: 2.857rem 0rem 2.857rem 2.857rem;
  background-color: white;
  justify-content: space-between;

  .outcomes-add {
    font-size: $Body-Default;
    margin-top: 0.5rem;
  }

  .button-outcome {
    display: flex !important;
    align-items: flex-end !important;
    margin-right: $spacing-lg;
  }

  .submit-button {
    cursor: pointer;
    height: 2.857rem;
    background-color: $gradientRight;
    @include button-style;
    border: none;
    border-radius: 0.313rem;
    @include font-style(1rem, $white, normal);
    @include set-padding(0.563rem, 2.563rem, 0.563rem, 2.563rem);

    &:focus {
      outline: none !important;
    }
  }

  .submit-button-custom {
    cursor: pointer;
    height: 2.857rem;
    background-color: $gradientRight;
    @include button-style;
    border: none;
    border-radius: 0.313rem;
    @include font-style(1rem, $white, bold);
    @include set-padding(0.563rem, 2.563rem, 0.563rem, 2.563rem);
  }
}

.org-users-text {
  .ui.orange.label {
    @include Body-Small($Primary);
    background-color: rgb(250, 235, 220) !important;
  }
}

.acc-users-communication {
  padding: 0 !important;
}

.custom-tab {
  .menu {
    margin-bottom: 0px !important;
    //background-color: #ffffff !important;
  }
  .outcome-admin {
    padding: 27px !important;
    .button-outcome {
      margin-right: 5px !important;
    }
  }
}

.output-format-table {
  margin-top: 0px !important;
  thead {
    tr {
      background-color: #fafafa;
      th {
        padding: 6px 32px !important;
      }
    }
  }
  tbody,
  tfoot {
    tr {
      background-color: #ffffff;
      td {
        padding: 16px 32px !important;
      }
    }
  }
}

.output-format-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.output-format-dropdown {
  min-height: 24px !important;
  input {
    margin: 5px !important;
  }
}
.ui.multiple.dropdown {
  padding: 0.22619048em 2.1em 0.22619048em 0.75rem;
}

.ui.multiple.search.dropdown {
  > .text {
    margin: 0.45238095em 0 0.45238095em 0;
    padding: 0;
    position: inherit;
  }

  > input.search {
    width: 0.1rem;
  }
}

@media (min-width: 1920px) {
  .ui.search.dropdown .menu {
    max-height: 16.02857143rem;
  }
}

.ui.multiple.dropdown > .label {
  vertical-align: unset;
}

.draggable-card-title {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: $Body-Default !important;
  line-height: 20px !important;
}

.output-format-drag-contianer {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 0 !important;
  // margin-top: 16px !important;
  .column.row {
    padding: 0px !important;
    margin: 0px !important;
  }

  .one.column.row > .column.label-contianer {
    padding-top: 0px !important;
  }

  .label-contianer {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important;
    justify-content: center !important;
    padding: 16px !important;

    .form-group {
      width: 100%;
      margin: 0px !important;
      textarea {
        width: 100%;
        font-size: $Body-Default !important;
        padding: 8px 14px !important;
      }
    }
  }
}

.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px !important;
  height: 200px !important;
  width: 100%;
}

// ============================ helps container styles ==================================
.help-tip-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

// button with outline
.btn-orange-outline {
  background-color: transparent !important;
  border: 0.063rem solid $gradientRight !important;
  color: $gradientRight !important;
  font-family: $fontFamily !important;
}

.btn-orange-outline:hover {
  background-color: $gradientRight !important;
  color: $white !important;
}

.ui.basic.grey.label {
  @include Body-Small(#767676);
  line-height: 0.9rem !important;
}

.margin-add-help-tip {
  // add help tip button margins
  @include set-margin($left: 0rem);
}

.margin-edit-help-page {
  // edit help page button margins
  @include set-margin;
}

.admin-menu-item {
  cursor: auto !important;
}

.add-new-user {
  .header {
    @include Body-Default-Semibold;
  }

  label {
    @include Body-Default;
  }

  .Primary-Button {
    padding: 0.625rem 1.4rem !important;
  }
}

.create-permission-group,
.edit-permission-group {
  .header {
    @include Body-Default-Semibold;
  }

  label {
    @include Body-Default;
  }

  .button-container {
    display: flex;
  }

  .ui.segment {
    box-shadow: none !important;
  }
}

.org-activity-segment {
  .no-color {
    padding: $spacing-lg $spacing-xl !important;
  }

  .row {
    margin: $spacing-lg $spacing-xl !important;
  }

  .form-group {
    margin: 0rem !important;
  }
}

.edit-venue-type,
.advance-search,
.edit-activity-type,
.edit-interview,
.edit-evaluation-method,
.edit-feature,
.create-new-feature,
.create-tip,
.edit-help-page,
.create-help-page,
.create-outcome,
.create-activity-type,
.create-industry-sector-type,
.create-venue-type {
  .header {
    @include Body-Default-Semibold;
  }

  label {
    @include Body-Default;
  }
}

.edit-venue-type,
.create-venue-type {
  .formik-custom-field {
    margin: 0rem 0rem 1.714rem !important;
  }

  .ui.section.divider,
  .content {
    margin: 0rem !important;
    padding: 0rem !important;
  }

  .container {
    padding: 1.5rem 1.5rem 0rem 1.5rem;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1.143rem 1.5rem;
  }
}

.create-output-format {
  .header {
    @include Body-Default-Semibold;
  }

  label {
    @include Body-Default;
  }

  .column {
    padding: 0px !important;
  }

  .row {
    margin: 0rem 0rem 0rem 1.2rem !important;
    column-gap: $spacing-lg !important;
  }
}

.permission-check {
  color: $Primary !important;
}

.permission-close {
  color: $Disable !important;
}

.permission-program-badge {
  margin-left: 0.625rem !important;
  border-radius: 0.25rem !important;
  background: transparent !important;
  @include Body-Small($Primary);
  text-align: center !important;
  padding: 0 !important;
  margin-top: -0.063rem;
}

.formula-label {
  margin-left: 0.625rem !important;
  border-radius: 0.25rem !important;
  background: rgba(239, 129, 35, 0.1);
  @include Body-Small($Primary);
  text-align: center !important;
  padding: 0.4em 0.833em;
  margin-top: -0.063rem;
}
