@import '../../../../variables.scss';
@import '../../../../mixins.scss';

.system-report-admin {
  padding: 1.25rem 0rem 0.938rem 42px;
  .com-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: $Body-Large;
    line-height: 1.75rem;
    letter-spacing: -0.017px;
    padding: 0rem ​0rem 1.875rem 1.875rem;
  }
}

.org-count-div {
  background: $orgBackground;
  border-radius: 0.25rem;
  height: 116px;
  width: fit-content;
}

.ongoing {
  .system-summary-grid {
    background-color: $white;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: row;
    height: 116px;
    .org-down {
      margin-top: 0.938rem !important;

      .act-type {
        margin-left: 20px !important;
      }
    }

    .org-down-act-type {
      margin-top: 0.938rem !important;
      margin-left: 18px !important;

      .act-type {
        margin-left: 20px !important;
      }
    }

    .org-count {
      display: flex;
      flex-direction: row;
      padding: 1.25rem 0rem 0rem 2.813rem;

      .title-org {
        font-style: normal;
        font-weight: normal;
        font-size: $Body-Small;
        line-height: 1.25rem;
        color: #000000;
        padding: 0.938rem ​0rem 0.375rem 0.438rem;
      }

      .adjust-left {
        margin-left: 8.125rem;
      }

      .count-value {
        font-style: normal;
        font-weight: 600;
        font-size: $h5;
        line-height: 2.5rem;
        letter-spacing: -0.019px;
        color: #000000;
      }
    }

    .activity-summary-row {
      .summary-details-table {
        margin: 0 !important;
        tbody {
          tr {
            @include font-style($spacing-sm);
            td {
              padding: 0;
              padding-right: 1.563rem;
              border: 0;
            }
          }
        }
      }
      .dev-act,
      .active-act,
      .completed-act {
        padding: 0 0 0 1.25rem;
        margin-bottom: 3.75rem;
        margin-right: 5rem;
        @media (max-width: 48rem) {
          margin-right: 0;
        }
      }
      .dev-act {
        border-left: 0.75rem solid #ff7800;
      }
      .active-act {
        border-left: 0.75rem solid #2d9a2c;
      }
      .completed-act {
        border-left: 0.75rem solid #002f9b;
      }
      .tot-no-holder {
        display: flex;
        flex-direction: column;
      }
      .tot-act-no {
        @include font-style(2rem, $info, 600);
        margin-bottom: 1.5rem;
      }
      .column {
        padding: 0;
      }
      padding-top: 2.857rem;
      .act-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    .left-align {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 8.125rem;

      .activity-name {
        margin-left: 2.813rem;
        label {
          flex: 1;
        }
      }
    }
    .left-align-sm {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 8.125rem;
      // margin-top: -1.25rem;

      .activity-name {
        margin-left: 2.813rem;
        label {
          flex: 1;
        }
      }
    }
  }
}

.completion {
  .system-summary-grid {
    background-color: $background;
    border-radius: 0.25rem;
    display: flex;
    flex-direction: row;
    height: 116px;
    .org-down {
      margin-top: 0.938rem !important;

      .act-type {
        margin-left: 20px !important;
      }
    }

    .org-down-act-type {
      margin-top: 0.938rem !important;
      margin-left: 20px !important;

      .act-type {
        margin-left: 18px !important;
      }
    }

    .org-count {
      display: flex;
      flex-direction: row;
      padding: 1.25rem 0rem 0rem 2.813rem;

      .title-org {
        font-style: normal;
        font-weight: normal;
        font-size: $Body-Small;
        line-height: 1.25rem;
        color: #000000;
        padding: 0.938rem ​0rem 0.375rem 0.438rem;
      }

      .adjust-left {
        margin-left: 8.125rem;
      }

      .count-value {
        font-style: normal;
        font-weight: 600;
        font-size: $h5;
        line-height: 2.5rem;
        letter-spacing: -0.019px;
        color: #000000;
      }
    }

    .activity-summary-row {
      .summary-details-table {
        margin: 0 !important;
        tbody {
          tr {
            @include font-style($spacing-sm);
            td {
              padding: 0;
              padding-right: 1.563rem;
              border: 0;
            }
          }
        }
      }
      .dev-act,
      .active-act,
      .completed-act {
        padding: 0 0 0 1.25rem;
        margin-bottom: 3.75rem;
        margin-right: 5rem;
        @media (max-width: 48rem) {
          margin-right: 0;
        }
      }
      .dev-act {
        border-left: 0.75rem solid #ff7800;
      }
      .active-act {
        border-left: 0.75rem solid #2d9a2c;
      }
      .completed-act {
        border-left: 0.75rem solid #002f9b;
      }
      .tot-no-holder {
        display: flex;
        flex-direction: column;
      }
      .tot-act-no {
        @include font-style(2rem, $info, 600);
        margin-bottom: 1.5rem;
      }
      .column {
        padding: 0;
      }
      padding-top: 2.857rem;
      .act-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
    .left-align {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 8.125rem;

      .activity-name {
        margin-left: 2.813rem;
        label {
          flex: 1;
        }
      }
    }
    .left-align-sm {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 8.125rem;
      // margin-top: -1.25rem;

      .activity-name {
        margin-left: 2.813rem;
        label {
          flex: 1;
        }
      }
    }
  }
}
