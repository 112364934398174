@import '../../variables.scss';
@import '../../mixins.scss';

.activity-img {
  border-radius: 0.125rem;
  box-shadow: 0 0.125rem 0.25rem 0 hsla(0, 0%, 76.9%, 0.5);
}

.desc-cell {
  margin-bottom: 1.25rem;

  .desc-title {
    @include font-style($Body-Small, $info, 600);
  }

  .right-side {
    margin-left: $spacing-sm !important;
  }

  p {
    @include font-style(0.75rem, $info);
  }

  .creation-summary {
    @include font-style(0.75rem, $info, 600);
  }

  .creation-info {
    @include display-flex(space-between, flex-start);

    @media (max-width: 48rem) {
      flex-direction: column;
    }

    label,
    font {
      @include font-style(0.75rem, $info);
    }

    label {
      font-weight: 600;
    }
  }
}

.objective-eval {
  display: list-item;
}

.evaluation-group-bg {
  width: fit-content;
  margin-bottom: 0.313rem;
  background: #fafafa;
  border: 0.031rem solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 0.125rem;
  padding: 0.063rem 0.5rem 0.063rem 0.5rem;
}

.preview-div {
  .preview {
    @include font-style(1rem, $amber, 600);
  }

  .export {
    margin-left: 2.857rem;
    @include font-style(1rem, $green, 600);
  }

  label {
    cursor: pointer;
  }
}

.action-new {
  display: flex;
  justify-content: flex-end;

  .ui.dropdown .menu > .item {
    border-top-width: 0;
    border: 0.063rem solid #e0e0e0;
    cursor: pointer;

    .preview {
      color: rgba(0, 0, 0, 0.87);
      cursor: pointer;
    }
  }

  .ui.dropdown .menu > .item:hover {
    border-top-width: 0;
    border: 0.063rem solid #e0e0e0;
    cursor: pointer;

    .preview {
      color: $gradientRight;
      cursor: pointer;
    }
  }

  @media (max-width: 48rem) {
    text-align: end;
  }
}

.new-lib-arr {
  .ui.segment {
    position: relative;
    background: #fff;
    margin: 1rem 0;
    padding: 0em 0em;
    border-radius: 0.28571429rem;
  }
}

.broswe-act {
  background-color: white;

  .div-desc {
    margin-bottom: 2.857rem !important;
    @include font-style(1rem, $info);
  }

  .search-label {
    @include font-style(0.75rem, $info);
  }

  .activitySearch {
    @media (max-width: 48rem) {
      width: 100%;
    }

    right: 0.625rem;
    width: 29.25rem;
    height: 2.857rem;
    border: 0.031rem;
    border-radius: 0.5rem;

    .ui.icon.input {
      width: 100% !important;

      input {
        border-radius: 0.25rem;
        border: 0.063rem solid #ced4da;
      }
    }

    .results.transition.visible {
      display: none !important;
    }
  }

  .no-values-div {
    @include display-flex();
    @include font-style();
  }
}

.library-act {
  margin: 0.3rem 0.313rem 0.125rem 0.063rem;

  .div-desc {
    margin-bottom: 2.857rem !important;
    @include font-style(1rem, $info);
  }

  .search-label {
    @include font-style(0.75rem, $info);
  }

  .activitySearch {
    @media (max-width: 48rem) {
      width: 100%;
    }

    margin: auto;
    width: 29.25rem;
    height: 2.857rem;
    border: 0.031rem;

    .ui.icon.input {
      width: 100% !important;

      input {
        border-radius: 0.5rem;
        border: 0.063rem solid #ced4da;
      }
    }

    .results.transition.visible {
      display: none !important;
    }
  }

  .no-values-div {
    @include display-flex();
    @include font-style();
  }
}

.browse-button-holder {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.25rem;
  width: 100%;
}

.intense-outcome {
  display: flex;
  flex-direction: column;
}

.outcome-scale {
  display: flex;
  flex-direction: row;
  margin-left: 1.25rem;

  .right-scale {
    margin-right: 0.313rem;
  }
}

.see-more-see-less-toggle {
  color: $gradientRight !important;
  font-weight: 400 !important;
  cursor: pointer;
  width: fit-content;
}
.report-filter {
  display: flex;
  flex-direction: row;
}

.filter-button {
  padding: 4px 16px;
  gap: 4px;
  width: 127px;
  height: 28px;
  left: 1412px;
  top: 221px;
  background: #ef8123 !important;
  border-radius: 4px 4px 0px 0px;
  transform: rotate(-90deg);
  font-size: 10px !important;
  line-height: 20px !important;
  color: #fff !important;
}

// .divider-margin {
//   .ui.divider {
//    margin-left: -10px;
//   }
// }
.filter-header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 22px;
  padding: 16px 24px 9px;

  .info-close {
    margin: 4px 0px 0px 8px;
    cursor: pointer;
  }

  .filter-text {
    font-weight: 600;
    margin-left: -10px;
    color: $black;
    font-size: $Body-Large;
  }

  .filter-save {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    color: $gradientRight;
  }

  .filter-reset {
    cursor: pointer;
    font-weight: 400;
    font-size: 14px;
    color: $subLabel;
    margin-left: 10px;
  }
  .filter-save {
    color: $gradientRight;
  }

  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  background-color: white;
  z-index: 1000;
}
.accordion-style-comp {
  margin-right: -62px;
  width: 1500px !important;
}
.selectable-title {
  cursor: pointer;
  padding-left: 7px;
  /* Add any additional styling for the selectable titles on hover */
}

.selectable-title:hover {
  background-color: #f0f0f0; /* Change the background color on hover */
}

.ui.accordion:not(.styled) .title ~ .content:not(.ui) {
  padding: 0.5em 0 1em;
  margin-left: 10px;
}

.advance-filter {
  .filter-body {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    // margin-right: 24px;
    width: 285px;
    margin-bottom: 2px;
    margin-left: 5px;
    // margin-top: 0px, 24px, 2px, 24px;
    .filter-label {
      @include Body-Default-Semibold;
      cursor: pointer;
      padding-top: 24px;
      padding-bottom: 18px;
      margin-left: 24px;
      font-size: $Body-Small;
      margin: 10px !important;
    }
    .filter-label-co {
      @include Body-Default-Semibold;
      color: $subLabel !important;
      padding-bottom: 18px;
      margin: 0px !important;
    }
    .two-step-radio-container-admin {
      margin-left: 0px !important;
      .ui.checkbox label:before {
        width: 28px !important;
        height: 16px !important;
      }
      .ui.toggle.checkbox label {
        padding-left: 40px;
      }
      .ui.toggle.checkbox label:after {
        width: 13.09px;
        height: 13.09px;
      }
      .ui.toggle.checkbox input:checked ~ label:after {
        left: 14px;
        top: 1px;
      }
      .ui.toggle.checkbox input ~ label:after {
        left: 1px;
        top: 1px;
      }
    }
    .ui.checkbox label {
      margin-bottom: 0px !important;
      padding-left: 30px;
      padding-bottom: 20px;
    }
    .ui.divider.divider-margin {
      margin: 6px -6px 0px -10px;
    }
    .filter-components-column {
      @include Body-Default;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      display: flex;
      flex-direction: column;
      .show-all-button {
        cursor: pointer;
        color: $Primary;
        margin-bottom: 18px;
      }
    }
    .filter-components-row {
      @include Body-Default;
      margin-top: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      display: flex;
      flex-direction: row;

      .grid-column {
        margin-right: 26px;
      }
    }
    .show-all-button {
      cursor: pointer;
      color: $Primary;
      margin-bottom: 18px;
    }
  }

  .dropdown-advanced-filter {
    margin-top: 15px;
  }

  .orange-reset {
    cursor: pointer;
    @include Body-Small($color: $subLabel);
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px !important;
    gap: 8px;

    height: 20px;

    background: $white;

    border: 1px solid $Gray-70;
    border-radius: 4px;

    img.resetImg {
      width: 12px !important;
      height: 12px !important;
    }
  }

  .filter-reset-flex {
    margin-right: 10px;
    align-items: flex-end;

    @include display-flex(right, center);
    .blue-label {
      @include Body-Small($color: $Primary);
      line-height: 20px !important;
      justify-content: flex-end;

      display: flex;
      margin-left: auto;
      flex-direction: row;
      justify-content: center; // Center horizontally
      align-items: center; // Center vertically
      padding: 0px 0px 0px 0px !important; // Remove left padding
      margin-left: 0 !important; // Remove left margin
      gap: 10px;

      min-width: 30px;
      height: 30px;

      background: $Primary-lighter;
      border-radius: 50%; // Make the label circular

      flex: end;
      order: 0;
      margin-right: 10px;
      flex-grow: 0;
    }
    // margin-right: -150px;
    // margin-top: -10px;
    // justify-content: flex-end;
    //margin-bottom: 20px;
    // margin-left: 120px;
  }
  .accordion-title {
    margin-bottom: 15px; /* Adjust as needed */
    justify-content: center;
    align-items: center; // Center vertically
  }
  .accordion-content {
    margin-left: 10px;
  }
  .custom-sidebar {
    width: 300px !important;
  }

  .btn-flex {
    margin-top: 8px;
    @include display-flex(space-between);
    height: 18px;
    .ui.horizontal.label {
      // margin-right: -10px;
    }
  }
  .btn-flex-act {
    @include display-flex(space-between);
  }
}

.advance-filter .filter-reset-flex .open-venue {
  margin-right: -111px;
  // margin-top: 8px;
  justify-content: center;
  align-items: center; // Center vertically
  display: flex;
}

.advance-filter .filter-reset-flex .open-outcomes {
  margin-right: -98px;
  // margin-top: 8px;
  justify-content: center;
  align-items: center; // Center vertically
  display: flex;
}

.advance-filter .filter-reset-flex .open-goal {
  margin-right: -116px;
  justify-content: center;
  // margin-top: 8px;
  align-items: center; // Center vertically
  display: flex;
}
.advance-filter .filter-reset-flex .open-department {
  margin-right: -92px;
  justify-content: center;
  // margin-top: 8px;
  align-items: center; // Center vertically
  display: flex;
}
.advance-filter .filter-reset-flex .open-cross-ref {
  margin-right: -78px;
  justify-content: center;
  // margin-top: 8px;
  align-items: center; // Center vertically
  display: flex;
}
.advance-filter .filter-reset-flex .open-act-type {
  margin-right: -108px;
  justify-content: center;
  // margin-top: 8px;
  align-items: center; // Center vertically
  display: flex;
}
.advance-filter .filter-reset-flex .open-act-status {
  margin-right: -83px;
  justify-content: center;
  // margin-top: 8px;
  align-items: center; // Center vertically
  display: flex;
}
.advance-filter .filter-reset-flex .open-participant {
  margin-right: -92px;
  // margin-top: 8px;
  justify-content: center;
  align-items: center; // Center vertically
  display: flex;
}
.advance-filter .filter-reset-flex .open-method {
  margin-right: -92px;
  // margin-top: 8px;
  justify-content: center;
  align-items: center; // Center vertically
  display: flex;
}

.upload-template-container {
  width: 188px;
}

.upload-template-link {
  padding-left: 0 !important;
  label {
    margin-top: 2.625rem !important;
    padding-left: 0 !important;
    font-weight: 400 !important;
    font-size: $Body-Default !important;
    line-height: 2.313rem !important;
    font-family: $fontFamily !important;
    text-align: left !important;
    letter-spacing: 0rem !important;
    color: $gradientRight !important;
    cursor: pointer !important;
  }
}
