@import '../../variables.scss';
@import '../../mixins.scss';

.custom-tabs {
  width: inherit;
  .ui.segment {
    box-shadow: none;
    border-radius: none;
    border: none;
  }
  .ui.menu.borderless {
    border: none;
    box-shadow: none;
    .item {
      flex: 1 !important;
      display: flex !important;
      flex-wrap: wrap !important;
      justify-content: center !important;
      @include Body-Default-Semibold($info);
      background-color: $orgBackground !important;
      border-right: 0.063rem solid $background !important;
      text-align: center !important;
    }
    .item.active {
      background-color: $white !important;
      color: $gradientRight !important;
    }
  }
}

.custom-tabs-mobile {
  width: inherit;
  .ui.borderless.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .item {
    width: -webkit-fill-available;
    display: flex !important;
    justify-content: center !important;
    color: $info !important;
    font-family: $fontFamily !important;
    font-size: $Body-Small !important;
    font-weight: 600 !important;
    line-height: 1.188rem !important;
    background-color: $background !important;
  }
  .item.active {
    background-color: $white !important;
    color: $gradientRight !important;
  }
}

.ui.tab.active.custom-tab {
  padding: 0 !important;
  background-color: $background !important;
}
.ui.button.account-export {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.857rem;
  min-width: 160px;
  background-color: transparent;
  color: $gradientRight;
  border: 0.125rem solid $gradientRight;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-weight: bold;
  line-height: 1.375rem;
  text-align: center;
}
.ui.button.account-export > .icon:not(.button) {
  height: auto !important;
}

.ui.button.account-export:hover,
.ui.buttons.account-export .button:hover {
  background-color: $amber;
  color: $white;
}

.ui.button.account-export:active {
  background-color: $gradientRight;
}
@media only screen and (max-width: 767px) {
  .ui.button.account-export {
    margin-top: 0.625rem;
  }
}
.ui.modal > .content {
  background: $background !important;

  .model-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .model-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: $spacing-lg !important;
  }

  .model-buttons-venue {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1.875rem !important;
  }

  @media only screen and (max-width: 767px) {
    .model-buttons {
      .export {
        margin-top: 1.25rem;
      }
    }
  }
  // small {
  //   margin-bottom: 2.5rem !important;
  // }
  label {
    color: $info;
    font-family: $fontFamily;
    font-size: $Body-Small;
    line-height: 1.063rem;
  }
}
.ui.button.billing {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.125rem;
  width: 10.188rem;
  background-color: $gradientRight;
  color: white;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-weight: bold;
  line-height: 1.375rem;
  text-align: center;
}

.ui.button.billing:hover,
.ui.buttons.billing .button:hover {
  background-color: $amber;
  color: $white;
}

.ui.button.billing:active {
  background-color: $gradientRight;
}
.ui.button.billing-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.125rem;
  width: 10.188rem;
  background-color: transparent;
  color: $red;
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-weight: bold;
  line-height: 1.375rem;
  text-align: center;
}
.billing-info {
  font-family: $fontFamily !important;
  font-size: $Body-Default !important;
  line-height: 1.375rem !important;
}
.ui.button.billing-end {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.125rem;
  width: 10.188rem;
  background-color: $red;
  color: white;
  border-radius: 0.25rem;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-weight: bold;
  line-height: 1.375rem;
  text-align: center;
}

.ui.button.billing-end:hover,
.ui.buttons.billing-end .button:hover {
  background-color: $lightRed;
  color: $white;
}

.ui.button.billing-end:active {
  background-color: $red;
}
.ui.button.billing-end-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.125rem;
  width: 10.188rem;
  background-color: transparent;
  color: $gradientRight;
  font-family: $fontFamily;
  font-size: $Body-Default;
  font-weight: bold;
  line-height: 1.375rem;
  text-align: center;
}
.goal-tab-container {
  display: flex;
}
